import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, map, switchMap } from 'rxjs';
import { UserActivityService } from '../services/user-activity.service';
import { selectUser } from '../store';

@Injectable({
	providedIn: 'root',
})
export class UserComparisonActivityCheckService  {
	constructor(
		private store: Store,
		private userActivityService: UserActivityService
	) {}

	/**
	 * Loads user comparison history on route entry.
	 * @author LWK
	 */
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this.store
			.select(selectUser)
			.pipe(
				switchMap((user) =>
					user
						? this.userActivityService
								.getUserComparisonActivity(true)
								.pipe(map((loaded) => true))
						: of(true)
				)
			);
	}
}
