import { APP_VERSION } from './version';
import { firebase } from './firebase';

export const environment = {
	production: true,
	version: APP_VERSION,
	api_base_url: 'https://app.shareastrength.com/api/v2',
	api_base_url_v3: 'https://app.shareastrength.com/api/v3',
	sas_video_url_youtube: 'https://www.youtube.com/embed/KSgKbFW-718',
	client_token: 'asdmaskldaskdnaskodmasdnjasndasldna;0amda',
	assessment_renewal_months: 6,
	geonames_base_url: 'http://api.geonames.org',
	anyone_can_register: true,
	enabled_features: {
		trait_feedback: false,
		trait_insights: false,
	},
	analytics_debug: false,
	firebase,
};
