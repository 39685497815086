import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { User } from 'src/app/store/user/user.model';

@Component({
	selector: 'user-image',
	template: `
		<figure class="figure">
			<img
				class="figure-img img-fluid"
				loading="lazy"
				[src]="imageUrl"
				[attr.alt]="description"
				[attr.title]="description"
				(error)="this.error = true"
			/>
			<a *ngIf="isAuthUser" routerLink="/me/account/image"
				><i class="fas fa-cog"></i
			></a>
		</figure>
	`,
	styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent implements OnInit {
	placeholderImageUrl = '/assets/img/avatar.jpg';

	error = false;

	@Input() user: User;

	@Input() isAuthUser = false;

	@Input() useAmoeba = false;

	@Input()
	@HostBinding('class.large')
	large = false;

	@Input()
	@HostBinding('class.small')
	small = false;

	get haveProfileImage(): boolean {
		return !!(
			this.user &&
			this.user?.profile &&
			this.user?.profile?.image_path
		);
	}

	get amoebaImageUrl(): string {
		return `https://shareastrength.nyc3.digitaloceanspaces.com/amoeba/${this.user.id}`;
	}

	get imageUrl(): string {
		return this.error
			? this.placeholderImageUrl
			: this.useAmoeba
			? this.amoebaImageUrl
			: this.user?.profile?.image_path ?? this.randomImageUrl;
	}

	get description(): string {
		if (null === this.user.profile) {
			return 'User Profile Image';
		}
		return (this.user?.profile?.name || 'User') + ' Profile Image';
	}

	get randomImageUrl(): string {
		const randomInt = Math.round(Math.random() * 10e3);
		return `https://picsum.photos/200/200?random=${this.user.id}`;
	}

	ngOnInit() {}
}
