import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { mergeMap, first, tap } from 'rxjs';

import { selectToken, selectUser } from '../store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private store: Store) {}

	/**
	 * Intercept HttpRequests and insert the active user's authorization token into the headers.
	 * @param req The request to manipulate
	 * @param next The handler we'll pass our new request to.
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		return this.store.select(selectToken).pipe(
			first(),
			mergeMap((token) => {
				/**
				 * Don't waste the time cloning and manipulating the request
				 * if we don't have an auth token.
				 */
				return next.handle(
					!!token
						? req.clone({
								setHeaders: {
									Authorization: `Bearer ${token}`,
								},
						  })
						: req
				);
			})
		);
	}
}
