import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Insight } from 'src/app/interfaces/insight';
import { Trait } from 'src/app/interfaces/trait';

@Component({
	selector: 'app-insight',
	templateUrl: './insight.component.html',
	styleUrls: ['./insight.component.scss'],
})
export class InsightComponent implements OnInit {
	@Input() insight: Insight;

	@Input() trait: Trait;

	@Input() compact = false;

	@Input()
	@HostBinding('class')
	class = '';

	get parsedTitle(): string {
		const value = this.insight.insightText.toLowerCase();
		if (value.indexOf('average age') > -1) {
			return 'Average Age';
		} else if (value.indexOf('top trait') > -1) {
			return 'Top Trait';
		} else if (value.indexOf('top five') > -1) {
			return 'Top Five';
		}
	}

	get parsedNumberText(): string {
		const matches = this.insight.insightText.match(/[\d.\%]{2,}/);
		return matches ? matches[0] : null;
	}

	get parsedNumber(): string {
		if (this.parsedNumberText !== null) {
			return this.parsedNumberText.replace('%', '');
		}
		return null;
	}

	ngOnInit(): void {
		console.log(this.insight);
		this.class = `${this.class} align-self-stretch insight d-flex flex-column align-items-center mb-3 mb-lg-0`;
	}
}
