import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { ResponseInterceptor } from './response-interceptor';
/**
 * Provides the HttpInterceptors needed.
 */
export const httpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	// { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
];
