import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { FeedItemComponent } from './ui/feed/feed-item/feed-item.component';
import { FormsModule } from '@angular/forms';
import { GlobalModule } from './modules/global/global.module';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './interceptors/index';
import { metaReducers, reducers } from './store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { SasUiModule } from './ui/sas-ui.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { TestingModule } from './modules/testing/testing.module';
import { TraitsModule } from './modules/traits/traits.module';
import { AnalyticsModule } from './modules/analytics/analytics.module';

@NgModule({
	declarations: [AppComponent, FeedItemComponent],
	imports: [
		AnalyticsModule,
		AppRoutingModule,
		ComponentsModule,
		DirectivesModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		NgbModule,
		SasUiModule,
		GlobalModule,
		StoreModule.forRoot(reducers, { metaReducers }),
		StoreDevtoolsModule.instrument({
			maxAge: 10,
		}),
		TraitsModule,
		TestingModule,
	],
	providers: [
		httpInterceptorProviders,
		{ provide: Window, useValue: window },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {}
}
