import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, zip, of, map, tap } from 'rxjs';
import { User } from 'src/app/store/user/user.model';
import { environment } from 'src/environments/environment';
import { Trait } from 'src/app/interfaces/trait';
import { ReportService } from 'src/app/services/report.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { TraitService } from 'src/app/services/trait.service';

export interface TraitFeedbackGranular {
	type: string;
	lower_bound: number;
	upper_bound: number;
	value: number;
	trait: number | Trait;
}

@Injectable({
	providedIn: 'root',
})
export class UserService {
	lastUser$: User = null;

	constructor(
		private http: HttpClient,
		private traitService: TraitService,
		private reportService: ReportService
	) {}

	getUser(id: string): Observable<User> {
		return (
			this.haveCachedUser(id) ||
			this.http.get<User>(`${environment.api_base_url}/users/${id}`)
		);
	}

	getUsers(): Observable<any[]> {
		return this.http.get<any>(`${environment.api_base_url}/users`);
	}

	getUserActivity(id: string): Observable<any> {
		return (
			this.haveCachedUser(id) ||
			this.http
				.get<any>(`${environment.api_base_url}/users/${id}/activity`)
				.pipe(map((items) => items.filter((item) => item !== null)))
		);
	}

	getOrderedTraitsForUser(
		id: string,
		fullTraits: boolean = false
	): Observable<ReportResultItem[]> {
		return zip(
			this.reportService.getReportForUser(id),
			this.traitService.traits
			// this.http.get<Trait[]>(`${environment.api_base_url}/traits`)
		).pipe(
			map(([report, traits]) => {
				return report.map((item: ReportResultItem, index: number) => {
					const trait = traits.find((trait) => trait.id === item.id);
					if (fullTraits) {
						item.trait = trait;
					} else {
						item.trait = trait.name_general;
					}
					item.order = index;
					item.rank = index + 1;
					return item;
				});
			})
		);
	}

	getUserReportHistory(id: string): Observable<any[]> {
		return this.http.get<any>(
			`${environment.api_base_url}/users/${id}/assessments`
		);
	}

	/**
	 * Get Aggregate OrderedTrait Feedback for User
	 *
	 * This returns the average trait feedback based on all
	 * SelectTopFive responses for user.
	 *
	 * @author LWK
	 */
	getAggregateOrderedTopFiveTraitsForUser(id: string): Observable<any> {
		return this.http
			.get<any>(
				`${environment.api_base_url}/users/${id}/feedback/orderedTraits`
			)
			.pipe(
				tap((orderedTraits) => {
					console.log('[Feedback] OrderedTopFive', orderedTraits);
				})
			);
	}

	/**
	 * Get all Feedback for User by a user
	 *
	 * Get feedback relationship between authenticated user
	 * and the user with supplied id.
	 *
	 * @author LWK
	 */
	getFeedbackForUser(id: string, friendId: string): Observable<any[]> {
		return this.http
			.get<any>(
				`${environment.api_base_url}/users/${id}/friends/${friendId}/feedback`
			)
			.pipe(
				tap((feedback) =>
					console.log('Feedback for Friend', id, feedback)
				)
			);
	}

	/**
	 * Store a feedback input for user
	 *
	 * @author LWK
	 */
	giveFeedbackForUser(
		id: string,
		friendId: string,
		feedback: TraitFeedbackGranular
	): Observable<any> {
		return this.http
			.post<any>(
				`${environment.api_base_url}/users/${id}/friends/${friendId}/feedback`,
				feedback
			)
			.pipe(
				tap((res) =>
					console.log(
						'Submitted Feedback for Friend',
						id,
						feedback,
						res
					)
				)
			);
	}

	haveCachedUser(id: string): Observable<User> {
		return this.lastUser$ && this.lastUser$.id === id
			? of(this.lastUser$)
			: null;
	}
}

/**
 * Trait Result relative to a User's Report
 *
 * @prop `dominance` The percentage weight of the user's score for the trait
 * @prop `order` The 0 index ranking of the user's score for the trait
 * @prop `rank` The 1 index ranking of the user's scrore for the trait
 * @prop `avgResponseTime` User's average response time per trait related question in `milliseconds`
 * @author LWK
 */
export interface ReportResultItem {
	avgResponseTime: number;
	dominance: number;
	id: number;
	score: number;
	trait: string | Trait;
	order?: number;
	rank?: number;
}
