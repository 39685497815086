<ng-container [ngTemplateOutlet]="backButton"></ng-container>

<article class="my-3" *ngIf="trait$ | async as trait">
	<header>
		<div class="trait-icon">
			<svg [appSpriteIcon]="trait.name_safe"></svg>
		</div>
		<h1 class="display-5">{{ trait.name_general }}</h1>
	</header>
	<blockquote class="blockquote">
		<p>{{ trait.haiku.content }}</p>
	</blockquote>
	<div *ngFor="let prop of content | keyvalue" class="trait-content">
		<h3>{{ prop.key | titlecase }}</h3>
		<p>{{ trait[prop.value].content_3p }}</p>
	</div>
	<div class="trait-insights">
		<h3>Insights</h3>
		<div class="d-lg-flex">
			<app-insight
				*ngFor="let insight of insights$ | async"
				class="col"
				[insight]="insight"
				[trait]="trait"
				[compact]="false"
			></app-insight>
		</div>
	</div>
</article>

<ng-container [ngTemplateOutlet]="backButton"></ng-container>

<ng-template #backButton>
	<div class="my-3" *ngIf="returnUrl$ | async as returnUrl">
		<a [routerLink]="returnUrl.routerLink" class="btn btn-outline-primary"
			><i class="fas fa-arrow-left"></i> {{ returnUrl.title }}</a
		>
	</div>
</ng-template>
