import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, share, map } from 'rxjs';
import { Demographics } from '../interfaces/demographics';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class DemographicsService {
	http = inject(HttpClient);

	// If we load the demographics for a trait, let's not ask for it again.
	private _cache = {};

	public getDemographics(traitId: number): Observable<Demographics> {
		if (this._cache[`${traitId}`])
			return of(this._cache[`${traitId}`]).pipe(share());
		return this.fetchDemographics(traitId).pipe(
			map((_trait) => {
				this._cache[`${traitId}`] = _trait;
				return this._cache[`${traitId}`];
			})
		);
	}

	private fetchDemographics(traitId: number): Observable<Demographics> {
		return this.http.get<Demographics>(
			`${environment.api_base_url_v3}/traits/${traitId}/demographics`
		);
	}
}
