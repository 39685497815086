import { Component, OnInit, HostListener } from '@angular/core';
import { TraitService } from 'src/app/services/trait.service';
import { TraitsModalService } from './traits-modal.service';
import { Trait } from 'src/app/interfaces/trait';
import { map, filter, switchMap, Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'sas-traits-modal-outlet',
	templateUrl: './traits-modal.component.html',
	styleUrls: ['./traits-modal.component.scss'],
})
export class TraitsModalComponent implements OnInit {
	traits$: Observable<Trait[]> = this.userService.user.pipe(
		filter((user) => user !== null),
		switchMap(() => this.traitService.traits),
		map((t) => t.sort((a, b) => (a.name_general > b.name_general ? 1 : -1)))
	);

	@HostListener('document:keydown.escape', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		if (this.traitsModalService.showTraitModal) {
			this.traitsModalService.showTraitModal = false;
		}
	}

	constructor(
		private userService: UserService,
		private traitService: TraitService,
		public traitsModalService: TraitsModalService
	) {}

	ngOnInit() {}
}
