import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { Survey, SurveyQuestion } from 'src/app/interfaces/survey';
import { SurveyService } from 'src/app/services/survey.service';

const QUESTION_TYPES = {
	RATING: 'rating',
	MULTIPLE_SELECT: 'multiple_select',
	TEXT: 'text',
};

@Component({
	selector: 'app-survey-question',
	templateUrl: './survey-question.component.html',
	styleUrls: ['./survey-question.component.scss'],
})
export class SurveyQuestionComponent implements OnInit, OnDestroy {
	questionTypes = QUESTION_TYPES;

	@Input() survey: Survey;

	@Input() question: SurveyQuestion;

	@Input() index: number = null;

	$modelChange = new Subject<any>();

	modelChangeSubscription: Subscription;

	get complete() {
		const response = this.question.response;
		if (Array.isArray(response)) {
			return response.length > 0;
		}
		return null !== response && '' !== response;
	}

	constructor(public surveyService: SurveyService) {}

	ngOnInit() {
		this.modelChangeSubscription = this.$modelChange
			.pipe(debounceTime(500))
			.subscribe(async () => {
				const res = await this.surveyService
					.storeResponse(
						this.survey.id,
						this.question.id,
						this.question.response
					)
					.toPromise();
			});
	}

	ngOnDestroy() {
		this.modelChangeSubscription.unsubscribe();
	}

	handleModelChange(event) {
		this.$modelChange.next(null);
	}

	handleCheckboxChange(event, value) {
		const foundIndex = (this.question.response as string[]).findIndex(
			(r) => r == value
		);
		if (foundIndex > -1) {
			(this.question.response as string[]).splice(foundIndex, 1);
		} else {
			this.question.response.push(value);
		}
		this.$modelChange.next(null);
	}
}
