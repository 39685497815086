import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/store/user/user.model';
import { Trait } from 'src/app/interfaces/trait';
import { map, tap, switchMap, take, filter, Observable, zip } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedbackRequest } from 'src/app/interfaces/feedback-request';
import { UserPreferences } from 'src/app/interfaces/user-preferences';
import { Store } from '@ngrx/store';
import * as UserActions from '../../../store/user/user.actions';
import { UserService } from 'src/app/services/user.service';
import { UserRelationship } from 'src/app/interfaces/user-relationship';
import { UserRole } from 'src/app/interfaces/user-role';
import { TraitService } from 'src/app/services/trait.service';
import { ReportService } from 'src/app/services/report.service';
import { selectUser } from 'src/app/store';
import { TeamUser } from 'src/app/interfaces/team-user';

enum FeedbackPole {
	Negative,
	Positive,
}

export interface TraitResult {
	avgResponseTime: number;
	dominance: number;
	id: number;
	score: number;
}

@Injectable({
	providedIn: 'root',
})
export class MeService {
	constructor(
		private http: HttpClient,
		private store: Store,
		private userService: UserService,
		private traitService: TraitService,
		private reportService: ReportService
	) {}

	getUserRoles(): Observable<UserRole[]> {
		return this.http.get<UserRole[]>(
			`${environment.api_base_url}/me/roles`
		);
	}

	/**
	 * Give polarity feedback for a particular user.
	 *
	 * @author LWK <lew@dankestudios.com>
	 */
	givePolarityFeedback(
		userId: string,
		traitId: number,
		pole: FeedbackPole,
		actionId = null
	): Observable<boolean> {
		return this.http.put<boolean>(
			`${environment.api_base_url}/me/feedback/give/${userId}`,
			{
				type: 'granular',
				trait: traitId,
				lower_bound: 0,
				upper_bound: 1,
				value: pole,
				request_action: actionId,
			}
		);
	}

	/**
	 * Let's request feedback from this user for the authenticated user, on
	 * this trait if there's one provided.
	 *
	 * @param user
	 * @param trait - optional
	 */
	requestFeedback(user: User, trait: Trait): Observable<boolean> {
		return this.http.put<boolean>(
			`${environment.api_base_url}/me/feedback/request/${user.id}/${
				trait ? trait.id : ''
			}`,
			null
		);
	}
	/**
	 * Let's get the requested feedback the authenticated user has made
	 *
	 * @return FeedbackRequest[]
	 */
	getRequestedFeedbackForAuthUser(): Observable<FeedbackRequest[]> {
		return this.http.get<any[]>(
			`${environment.api_base_url}/me/feedback/requested`
		);
	}

	getFeedbackRequestsForAuthUser(): Observable<FeedbackRequest[]> {
		return this.http.get<FeedbackRequest[]>(
			`${environment.api_base_url}/me/feedback/received-requests`
		);
	}

	/**
	 * Retrieve preferences for the authenticated user.
	 *
	 * @author Austin Farmer <austin@farmer.codes>
	 * @returns Observable<UserPreferences>
	 */
	getUserPreferences(): Observable<UserPreferences> {
		// debugger; // Use REFRESH user action to update userpreferences
		return this.http
			.get<UserPreferences>(`${environment.api_base_url}/me/preferences`)
			.pipe(
				tap((userPreferences) =>
					this.store
						.select(selectUser)
						.pipe(
							take(1),
							tap((user: User) => {
								user.preferences = userPreferences;
								this.store.dispatch(UserActions.refresh(user));
							})
						)
						.subscribe()
				)
			);
	}

	/**
	 * Get Ordered Traits Results for User
	 *
	 * @author LWK
	 */
	getOrderedTraitsForUser(): Observable<TraitResult[]> {
		return this.userService.user.pipe(
			filter((u) => u !== null),
			switchMap((u) => this.reportService.getReportForUser(u.id))
		);
	}

	/**
	 * Get ordered traits for user from Store
	 * @author LWK
	 * @debug _userService.user_ occassionally sends back array vs object...
	 * need to investigate.
	 */
	getStoredOrderedTraitsForUser(count = 25): Observable<Trait[]> {
		return zip(
			this.userService.user.pipe(take(1)),
			this.traitService.traits.pipe(take(1))
		).pipe(
			map(([user, traits]) => {
				// Sometimes it's an array, sometimes it's a map WTF Lew
				if (user.orderedTraitIds) {
					let orderedTraitIds = user.orderedTraitIds;
					if (typeof user.orderedTraitIds === 'object') {
						orderedTraitIds = Object.values(user.orderedTraitIds);
					}
					return orderedTraitIds
						.map((id) => traits.find((t) => t.id === id))
						.slice(0, count);
				}
				return [];
			})
		);
	}

	/**
	 * Update the preferences for the authenticated user.
	 * @author Austin Farmer <austin@farmer.codes>
	 * @param args The preferences to be updated.
	 * @returns Observable<UserPeferences>
	 */
	updateUserPreferences(args: any) {
		return this.http
			.patch<UserPreferences>(
				`${environment.api_base_url}/me/preferences`,
				args
			)
			.pipe(
				switchMap((userPreferences) =>
					this.store.select(selectUser).pipe(
						take(1),
						tap((user: User) => {
							user.preferences = userPreferences;
							this.store.dispatch(UserActions.refresh(user));
						})
					)
				)
			);
	}

	/**
	 * Get user Following list
	 *
	 * @author LWK<lew@dankestudios.com>
	 */
	public getFollowingListForUser(
		seed = '12345',
		page = 1,
		count = 20
	): Observable<UserRelationship[]> {
		return this.http.get<UserRelationship[]>(
			`${environment.api_base_url}/me/following?seed=${seed}&page=${page}pageSize=${count}`
		);
	}
	/**
	 * Get user Follow Suggestions
	 *
	 * @author LWK<lew@dankestudios.com>
	 */
	public getFollowSuggestionsForUser(count = 3): Observable<any[]> {
		return this.http.get<any[]>(
			`${environment.api_base_url}/me/suggestions/follow?pageSize=${count}`
		);
	}
	/**
	 * Check follow status for user
	 *
	 * @author LWK<lew@dankestudios.com>
	 */
	public getFollowStatusForUser(userId: string): Observable<any> {
		return this.http.get<any>(
			`${environment.api_base_url}/me/friends/${userId}`
		);
	}
	/**
	 * Toggle Follow Status for user with id
	 *
	 * @author LWK<lew@dankestudios.com>
	 */
	public updateFollowStatusForUser(
		userId: string,
		follow: boolean
	): Observable<any> {
		return follow ? this._followUser(userId) : this._unfollowUser(userId);
	}
	private _followUser(userId: string): Observable<any> {
		return this.http.put<any>(
			`${environment.api_base_url}/me/friends/${userId}`,
			null
		);
	}
	private _unfollowUser(userId: string): Observable<any> {
		return this.http.delete<any>(
			`${environment.api_base_url}/me/friends/${userId}`
		);
	}

	/**
	 * Get Teams for User
	 *
	 */
	public getTeams() {
		return this.http.get<TeamUser[]>(
			`${environment.api_base_url}/me/teams`
		);
	}

	/**
	 * Get a user's team by ID
	 *
	 */
	public getTeam(id: number) {
		return this.http.get<TeamUser>(
			`${environment.api_base_url}/me/teams/${id}`
		);
	}

	/**
	 * Refresh Teams in user store
	 *
	 */
	public refreshTeams() {
		return this.getTeams().pipe(
			tap((teams) =>
				this.store.dispatch(UserActions.refreshTeams({ teams }))
			),
			map(() => true)
		);
	}

	/**
	 * Accept or reject a team invite
	 *
	 */
	public respondToTeamInvite(invite_id: number, accept: boolean) {
		return this.http.put<boolean>(
			`${environment.api_base_url}/me/teams/invite/${invite_id}/${
				accept ? 'accept' : 'reject'
			}`,
			{}
		);
	}
}
