<div class="traits-modal">
	<div class="container">
		<nav class="trait-list">
			<a *ngFor="let trait of traits"
				[href]="'#trait-'+trait.id"
				class="trait-list-item"
				[class.active]="(this.traitsModalService.currentTraitId$|async) === trait.id"
				(click)="showTrait($event, trait.id)"
				placement="top"
				[ngbTooltip]="trait.name_general"
			>
				<app-trait-icon-circle [trait]="trait"></app-trait-icon-circle>
			</a>
			<div class="trait-list-item">
				<button class="btn btn-primary btn-sm" (click)="close()">Dismiss</button>
			</div>
		</nav>
		<div *ngIf="currentTrait$|async as currentTrait" class="trait-display">
			<div class="row my-5 align-items-lg-center">
				<div class="col-lg-3">
					<h2 class="h1">{{currentTrait.name_general}}</h2>
					<p class="lead">{{currentTrait.haiku.content}}</p>
				</div>
				<div class="col-lg-9">
					<div class="row">
						<div class="col-lg-12">
							<h3 class="text-center">Strategy</h3>
							<p class="small">{{currentTrait.strategy.content_3p}}</p>
						</div>
						<div class="col-lg-6">
							<h3 class="text-center">At your Best</h3>
							<p class="small">{{currentTrait.sweetspot.content_3p}}</p>
						</div>
						<div class="col-lg-6">
							<h3 class="text-center">In Overdrive</h3>
							<p class="small">{{currentTrait.blindspot.content_3p}}</p>
						</div>
						<ng-container *ngIf="insights$|async as insights">
							<div class="col-lg-12" *ngIf="insights.length">
								<h3 class="text-center">Insights</h3>
								<!-- <app-insight-group [data]="insights"></app-insight-group> -->
								<div class="d-flex flex-column flex-lg-row">
									<app-insight *ngFor="let item of insights" [insight]="item.insight" [trait]="item.trait" class="col" [compact]="false"></app-insight>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
