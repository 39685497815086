import { Component, Input, HostBinding } from '@angular/core';
import { User } from 'src/app/store/user/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-user-tease',
	templateUrl: './user-tease.component.html',
	styleUrls: ['./user-tease.component.scss'],
})
export class UserTeaseComponent {
	@Input() user: User;

	@Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';

	@Input() stacked = false;

	@Input() theme: 'light' | 'dark' = 'dark';

	@Input() link: boolean = true;

	@Input() hideMissing = false;

	@Input() isAuthUser = false;

	@HostBinding('class')
	get className() {
		return `user-tease--${this.size} user-tease--${this.theme}`;
	}

	get generation() {
		return this.user.profile && this.user.profile.dob
			? this.userService.getUserGeneration(this.user.profile.dob)
			: 'No generation info';
	}

	compareLink = ['/me/community/compare'];

	constructor(private userService: UserService) {}

	getCompareLinkParams(term) {
		return {
			term: term,
			termValue: this.user.profile ? this.user.profile[term] : 'N/A',
		};
	}
}
