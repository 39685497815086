import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { NAVIGATION_LINKS } from '../../navigation.constants';

@Component({
	selector: 'app-navigation-static',
	template: `
		<div *ngFor="let item of navItems$ | async" class="nav-item">
			<a
				class="nav-link"
				routerLinkActive="active"
				[routerLinkActiveOptions]="{ exact: item.exactMatch }"
				[routerLink]="item.routerLink"
				[innerHTML]="item.text"
			></a>
		</div>
	`,
	styleUrls: ['./navigation-static.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationStaticComponent {
	navItems = NAVIGATION_LINKS;

	navItems$: Observable<any> = this.userService.user.pipe(
		map((user) => {
			const items = [...this.navItems];
			if (user && user.teams.length) {
				const insertAfter = this.navItems.findIndex(
					(item) => item.text === 'Following'
				);
				items.splice(insertAfter + 1, 0, {
					text: 'Teams',
					routerLink: `/me/teams`,
					exactMatch: false,
				});
			}
			return items;
		}),
		startWith(this.navItems)
	);

	constructor(private userService: UserService) {}
}
