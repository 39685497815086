import { createReducer, on } from '@ngrx/store';
import { UserAssessment } from './assessment.model';
import { loadAssessment } from './assessment.actions';
import { logout } from '../user/user.actions';

const initialState: UserAssessment = null;

const handleLoadAssessment = (
	state: UserAssessment,
	assessment: UserAssessment
) => assessment;

const resetAssessment = (state: UserAssessment) => {
	return null;
};

const _assessmentReducer = createReducer(
	initialState,
	on(loadAssessment, handleLoadAssessment),
	on(logout, resetAssessment)
);

export function assessmentReducer(state, action) {
	return _assessmentReducer(state, action);
}
