import { Component } from '@angular/core';
import { NAVIGATION_LINKS, NavigationLink } from '../../navigation.constants';
import { Observable, map, startWith } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
	showMenu = false;

	navItems: NavigationLink[] = NAVIGATION_LINKS;

	navItems$: Observable<any> = this.userService.user.pipe(
		map((user) => {
			const items = [...this.navItems];
			if (user && user.teams.length) {
				items.splice(1, 0, {
					text: 'Teams',
					routerLink: `/me/teams`,
					exactMatch: false,
				});
			}
			return items;
		}),
		startWith(this.navItems)
	);

	secondaryNavItems = [
		{
			url: 'https://www.shareastrength.com/privacy-policy',
			text: 'Privacy',
			target: '_blank',
		},
		{
			url: 'https://www.shareastrength.com/terms',
			text: 'Terms',
			target: '_blank',
		},
		{
			url: 'https://www.shareastrength.com/cookies',
			text: 'Cookies',
			target: '_blank',
		},
		{ routerLink: '/auth/logout', text: 'Logout' },
	];

	constructor(private userService: UserService) {}

	open(event: any, item: any) {
		event.preventDefault();
		window.open(item.url, item.target);
	}
}
