import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/store/user/user.model';

@Component({
	selector: 'app-user-share-results',
	templateUrl: './user-share-results.component.html',
	styleUrls: ['./user-share-results.component.scss'],
})
export class UserShareResultsComponent implements OnInit {
	@Input() user: User;

	// http://localhost:4200/auth/signup/fb3fe290-1cce-43e4-805b-25be7d968f14?referral=360

	shareLink = '';

	shareOptions: { title; icon; href; handler }[] = [];

	constructor() {}

	ngOnInit() {
		const { protocol, host } = window.location;

		this.shareLink = `${protocol}//${host}/auth/signup/${this.user.id}`;

		this.shareOptions = [
			{
				title: 'Facebook',
				icon: 'fab fa-facebook-square',
				href: `//www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
					this.shareLink
				)}`,
				handler: null,
			},
			{
				title: 'Twitter',
				icon: 'fab fa-twitter',
				href: `//twitter.com/share?text=${encodeURIComponent(
					'NEED CTA FOR SHARE TITLE'
				)}&url=${encodeURIComponent(this.shareLink)}`,
				handler: null,
			},
			{
				title: 'Email',
				icon: 'far fa-envelope',
				href: `mailto:?subject${encodeURIComponent(
					'NEED CTA FOR EMAIL TITLE'
				)}&body=${encodeURIComponent(
					'Placeholder body copy, add link to share URL: ' +
						this.shareLink
				)}`,
				handler: null,
			},
			{
				title: 'Download',
				icon: 'fas fa-download',
				href: `/me/report/download`,
				handler: null,
				// handler: e => {
				// 	e.preventDefault();
				// 	// Do download here...
				// 	window.alert('Do download');
				// }
			},
		];
	}

	getShareLink() {
		return 'http';
	}

	noop() {}
}
