import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from '../store/user/user.model';
import { map, switchMap, tap, Observable, of } from 'rxjs';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';
import { selectUser } from '../store';
import { AuthenticationV3Service } from '../modules/authentication/services/authentication-v3.service';

/**
 * Auth Guard Check
 *
 * Confirms the user is logged in. Redirects to login
 * page if not.
 *
 * @author LWK
 */
@Injectable({
	providedIn: 'root',
})
export class AuthGuardService {
	constructor(
		private store: Store,
		private auth: AuthenticationService,
		private authV3: AuthenticationV3Service,
		private router: Router
	) {}

	private userIsInStore = false;

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this.store.select(selectUser).pipe(
			map((user: User) => (user ? true : false)),
			switchMap((userIsInStore: boolean) => {
				if (userIsInStore) {
					this.userIsInStore = true;
					return this.authV3.validateIsActivelyAuthenticated();
				}
				this.userIsInStore = false;
				return of(false);
			}),
			switchMap((userIsAuthenticated: boolean) => {
				if (this.userIsInStore && !userIsAuthenticated) {
					return this.authV3.doLogout().pipe(
						tap(() => this.router.navigateByUrl('auth/login')),
						switchMap(() => of(false))
					);
				} else if (!userIsAuthenticated) {
					this.router.navigateByUrl('/auth/login');
				}
				return of(this.userIsInStore);
			})
		);
	}
}
