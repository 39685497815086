import { AdminCanActivateGuard } from './modules/admin/middleware/admin-can-activate.service';
import { AuthGuardService } from './middleware/auth-guard.service';
import { LayoutComponent } from './components/layout/layout.component';
import { NgModule } from '@angular/core';
import { ReportExportComponent } from './components/report-export/report-export.component';
import { Router, RouterModule, Routes } from '@angular/router';
import { SeoGuardService } from './middleware/seo-guard.service';
import { UserAssessmentCheckService } from './middleware/user-assessment-check.service';
import { UserComparisonActivityCheckService } from './middleware/user-comparison-activity-check.service';
import { UserReportCheckService } from './middleware/user-report-check.service';
import { UserTraitsCheckService } from './middleware/user-traits-check.service';

const AuthGuards = [
	AuthGuardService,
	UserReportCheckService,
	UserTraitsCheckService,
	// SurveyCheckService,
	SeoGuardService,
];

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/auth/login',
	},
	{
		path: 'auth',
		canActivate: [SeoGuardService],
		canActivateChild: [SeoGuardService],
		loadChildren: () =>
			import('./modules/authentication/authentication.module').then(
				(m) => m.AuthenticationModule
			),
	},
	{
		// Onboarding
		path: 'onboarding',
		canActivate: [AuthGuardService],
		canActivateChild: [SeoGuardService],
		loadChildren: () =>
			import('./modules/onboarding/onboarding.module').then(
				(m) => m.OnboardingModule
			),
	},
	{
		// Assessment
		path: 'me/assessment',
		canActivate: [AuthGuardService],
		canActivateChild: [SeoGuardService],
		loadChildren: () =>
			import('./modules/assessment/assessment.module').then(
				(m) => m.AssessmentModule
			),
	},
	{
		// Report Export
		path: 'me/report/download',
		canActivate: AuthGuards,
		component: ReportExportComponent,
		data: {
			title: 'Your ShareaStrength Report',
		},
	},
	{
		// Admin
		path: 'admin',
		canActivate: [AuthGuardService, AdminCanActivateGuard],
		canActivateChild: [SeoGuardService],
		loadChildren: () =>
			import('./modules/admin/admin.module').then((m) => m.AdminModule),
	},
	{
		/**
		 * Sidebar/Main/Sidebar Layouts are fed
		 * through the layout component. The layout
		 * service is used to change which parts
		 * are visible via routeGuards.
		 */
		path: '',
		component: LayoutComponent,
		canActivate: [UserComparisonActivityCheckService],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: '/auth/login',
			},
			{
				// Me (Dashboard)
				// '/me/'
				path: 'me',
				canActivate: AuthGuards,
				canActivateChild: [SeoGuardService],
				loadChildren: () =>
					import('./modules/me/me.module').then((m) => m.MeModule),
			},
			{
				// User
				// '/user/:id'
				path: 'user',
				canActivate: [...AuthGuards, UserAssessmentCheckService],
				canActivateChild: [SeoGuardService],
				loadChildren: () =>
					import('./modules/user/user.module').then(
						(m) => m.UserModule
					),
			},
			{
				path: 'user/logout',
				redirectTo: 'auth/login',
			},
			{
				// Traits
				// @Devnote we moved general trait display to a modal interface,
				// this likely will remain unused unless we combine traits/insights
				// into one module
				path: 'traits',
				canActivate: [...AuthGuards, UserAssessmentCheckService],
				canActivateChild: [SeoGuardService],
				loadChildren: () =>
					import('./modules/traits/traits.module').then(
						(m) => m.TraitsModule
					),
			},
			/* {
			// Demographics
			path: 'demographics'
			},*/
			/**
			 * @devnote Disabling insights for beta
			 */
			// {
			// 	path: 'insights',
			// 	canActivate: [...AuthGuards, UserAssessmentCheckService],
			// 	canActivateChild: [SeoGuardService],
			// 	loadChildren: () =>
			// 		import('./modules/insights/insights.module').then(
			// 			m => m.InsightsModule
			// 		)
			// }
		],
	},
	{
		path: 'testing/surveys',
		canActivate: AuthGuards,
		loadChildren: () =>
			import('./modules/surveys/surveys.module').then(
				(m) => m.SurveysModule
			),
	},
	{
		path: 'teams',
		canActivate: AuthGuards,
		loadChildren: () =>
			import('./modules/teams/teams.module').then((m) => m.TeamsModule),
	},
	{ path: '**', redirectTo: 'auth/login' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
			// enableTracing: true,
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor(private router: Router) {}
}
