<div class="modal modal-trait fade d-block show" tabIndex="-1" role="dialog">
	<div
		class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
		role="document"
	>
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between">
				<strong class="modal-title">Help/Feedback</strong>
				<button
					class="btn btn-link btn-sm btn-close"
					(click)="helpFormService.hideModal(); clear()"
				>
					Cancel
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group">
					<p class="form-text">Feedback/request category:</p>
					<!-- Review Custom Radio Below -->
					<!--
						<div class="custom-control custom-radio custom-radio-inline">
							<input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
							<label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
						</div>
					-->
					<!-- <div
						*ngFor="let _category of categories"
						class="form-check form-check-inline"
					>
						<input
							class="form-check-input"
							type="radio"
							[name]="_category"
							[value]="_category"
							[(ngModel)]="category"
						/>
						<label
							class="form-check-label"
							[for]="_category"
							>{{ _category }}</label
						>
					</div> -->
					<div
						*ngFor="let _category of categories"
						class="custom-control custom-radio custom-control-inline"
					>
						<input
							type="radio"
							class="custom-control-input"
							[id]="_category"
							[name]="_category"
							[value]="_category"
							[(ngModel)]="category"
						/>
						<label class="custom-control-label" [for]="_category">{{
							_category
						}}</label>
					</div>
				</div>
				<div class="form-group">
					<label for="message">Your Message</label>
					<textarea
						rows="5"
						class="form-control"
						name="message"
						[(ngModel)]="message"
						placeholder="Type your message..."
					></textarea>
					<small *ngIf="category == 'Error/Bug Report'" class="form-text text-muted">*When reporting bugs, please include information about the page you were on and what you were doing before the issue occurred.</small>
				</div>
			</div>
			<div class="modal-footer justify-content-center">
				<button
					class="btn btn-primary"
					(click)="dismiss()"
					[attr.disabled]="category && message != '' ? null : true"
				>
					Submit
				</button>
			</div>
		</div>
	</div>
</div>
<div class="modal-backdrop fade show"></div>
