import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TraitInsight } from '../interfaces/trait-insight';

@Injectable({
	providedIn: 'root',
})
export class InsightsService {
	constructor(private http: HttpClient) {}

	getInsightsForTrait(
		traitId: number,
		take: number = 3,
		page: number = 0
	): Observable<TraitInsight[]> {
		return this.getInsightsForGroup('trait', traitId, take, page);
	}

	getInsightsForOccupation(occupationId: number): Observable<any[]> {
		return this.getInsightsForGroup('occupation', occupationId);
	}

	getInsightsForPostalCode(postalCode: number | string): Observable<any[]> {
		return this.getInsightsForGroup('location', postalCode);
	}

	private getInsightsForGroup(
		group: 'trait' | 'occupation' | 'location',
		id: any,
		take?: number,
		page?: number
	): Observable<any[]> {
		let url = `${environment.api_base_url}/users/insights/${group}/${id}`;
		if (typeof take !== undefined && typeof page !== undefined) {
			url += `?take=${take}&skip=${take * page}`;
		}
		return this.http.get<any[]>(url);
	}
}
