import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	Observable,
	debounceTime,
	fromEvent,
	map,
} from 'rxjs';

export enum DEVICE_SIZE {
	XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg',
	XL = 'xl',
	XXL = 'xxl',
}

export const DEFAULT_MOBILE_DEVICE_SIZE = DEVICE_SIZE.MD;

@Injectable({
	providedIn: 'root',
})
export class DeviceService {
	/**
	 * Bootstrap grid breakpoints to match with device
	 * width sizes.
	 *
	 * Breakpoints are inclusive and ascending,
	 * meaning xs represents all sizes 0 <= px < 576 etc.
	 */
	breakPoints: { [key: string]: number } = {
		xs: 0,
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200,
		xxl: 1400,
	};

	breakPointKeys: string[] = Object.keys(this.breakPoints);

	breakPointValues: number[] = Object.values(this.breakPoints);

	/**
	 * Observable viewport size
	 */
	viewportSize$: BehaviorSubject<DEVICE_SIZE> = new BehaviorSubject(null);

	constructor(private window: Window) {
		this.updateViewportSize();
		fromEvent(this.window, 'resize')
			.pipe(debounceTime(30))
			.subscribe(() => this.updateViewportSize());
	}

	updateViewportSize(width?: number): void {
		const viewportWidth = width ?? this.window.innerWidth;
		let breakPointIndex = 0;
		for (let i = 0; i < this.breakPointValues.length; i++) {
			if (
				viewportWidth < this.breakPointValues[i] ||
				breakPointIndex === this.breakPointValues.length - 1
			) {
				break;
			}
			breakPointIndex++;
		}
		this.viewportSize$.next(
			this.breakPointKeys[breakPointIndex] as DEVICE_SIZE
		);
	}

	deviceSizeMax(breakPoint: DEVICE_SIZE): Observable<boolean> {
		return this.viewportSize$.pipe(
			map(() => this.window.innerWidth < this.breakPoints[breakPoint])
		);
	}

	deviceSizeMin(breakPoint: DEVICE_SIZE): Observable<boolean> {
		return this.viewportSize$.pipe(
			map(() => this.window.innerWidth >= this.breakPoints[breakPoint])
		);
	}

	deviceSizeBetween(
		minBreakPoint: DEVICE_SIZE,
		maxBreakPoint: DEVICE_SIZE
	): Observable<boolean> {
		return this.viewportSize$.pipe(
			map(
				() =>
					this.window.innerWidth >= this.breakPoints[minBreakPoint] &&
					this.window.innerWidth < this.breakPoints[maxBreakPoint]
			)
		);
	}
}
