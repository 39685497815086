<ng-container *ngIf="traitsModalService.showModal$|async">
	<sas-traits-modal-v2
		*ngIf="traits$ | async as traits"
		[search]="traitsModalService.search"
		[traits]="traits"
		(dismiss)="traitsModalService.showTraitModal = false; traitsModalService.search = ''"
	></sas-traits-modal-v2>
    <!-- <sas-traits-modal
        *ngIf="traitsModalService.showTraitModal"
        [search]="traitsModalService.search"
        [traits]="traits"
        (dismiss)="traitsModalService.showTraitModal = false; traitsModalService.search = ''"
	></sas-traits-modal> -->
	<!-- <sas-trait-modal
		*ngIf="traitsModalService.showTraitDetailModal"
		[traitId]="traitsModalService.currentTraitId"
		[traits]="traits"
		(dismiss)="traitsModalService.hideTraitDetailModal()"
	></sas-trait-modal> -->
</ng-container>
