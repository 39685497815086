import { Component } from '@angular/core';
import { Observable, of, map } from 'rxjs';
import { Survey } from 'src/app/interfaces/survey';
import { SurveyService } from 'src/app/services/survey.service';
import { SurveyModalService } from './survey-modal.service';

@Component({
	selector: 'app-survey-modal',
	templateUrl: './survey-modal.component.html',
	styleUrls: ['./survey-modal.component.scss'],
})
export class SurveyModalComponent {
	get currentSurvey(): Observable<Survey> {
		const id = this.surveyModalService.surveyId;
		return id
			? this.surveyService.surveys.pipe(
					map((surveys) => surveys.find((s) => s.id === id))
			  )
			: of(null);
	}

	constructor(
		public surveyService: SurveyService,
		public surveyModalService: SurveyModalService
	) {}

	close(event, force = false) {
		if (
			force ||
			event.target.classList.contains('modal-backdrop') ||
			event.target.classList.contains('modal')
		) {
			this.surveyModalService.dismissModal();
		}
	}
}
