import { jsPDF } from 'jspdf';
import { DateTime } from 'luxon';
import { User } from '../store/user/user.model';
import { TraitResult } from '../modules/me/services/me.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Trait } from '../interfaces/trait';
import { getTraitSafeName } from 'lib/trait/get-safe-name';
import { Canvg } from 'canvg';

interface TraitResultWithTrait extends TraitResult {
	rank: number;
	trait: Trait;
}

const inToPx = (number: number): number => number * 96;

const pxToIn = (number: number): number => number / 96;

const BLACK = '#39373c';
const PURPLE = '#4f1fff';

const FONT_SIZE_NORMAL = 12;
const FONT_SIZE_QUOTE = 14;
const FONT_SIZE_HEADER = 30;

const LINE_SPACING = 0.125 * 3;

const PADDING = 1;

const lineHeightFactor = 1.5;

const maxWidth = 8.5 - PADDING * 2;

export class ReportExporter {
	private doc: jsPDF;

	private http: HttpClient;

	user: User;

	traits: TraitResultWithTrait[];

	flow: TraitResultWithTrait[];

	friction: TraitResultWithTrait[];

	inBetween: TraitResultWithTrait[];

	amoeabaSvgCode: string;

	fileName: string;

	indexItems = [
		{ title: 'Your Profile', page: '3' },
		{ title: 'Flow', page: '4' },
		{ title: 'Friction', page: '15' },
		{ title: 'In-Between', page: '26' },
		{ title: 'The Amoeba', page: '57' },
		{ title: "What's next?", page: '59' },
	];

	traitMeta = [
		{ key: 'sweetspot', title: 'At Your Best' },
		{ key: 'blindspot', title: 'In Overdrive' },
		{ key: 'strategy', title: 'Strategy' },
	];

	traitMetaTitle = [];

	constructor(
		user: User,
		traits: TraitResult[],
		flow: TraitResult[],
		friction: TraitResult[],
		inBetween: TraitResult[],
		amoeabaSvgCode: string,
		http: HttpClient
	) {
		this.http = http;
		this.user = user;
		this.traits = traits as TraitResultWithTrait[];
		this.flow = flow as TraitResultWithTrait[];
		this.friction = friction as TraitResultWithTrait[];
		this.inBetween = inBetween as TraitResultWithTrait[];
		this.amoeabaSvgCode = amoeabaSvgCode;

		this.setFileName(user);
		this.doc = new jsPDF({
			orientation: 'portrait',
			unit: 'in',
			format: 'letter',
		});
		this.doc.setFont('helvetica', 'normal', 'normal').setTextColor(BLACK);
	}

	async generate(): Promise<void> {
		await this.createPages();
	}

	download(): void {
		this.doc.save(this.fileName);
	}

	public async createPages(): Promise<void> {
		await this.addCoverPage();
		this.addIndexPage();
		this.addProfileIntroPage();
		let page = 4;
		page = this.addSectionIntroPage('Flow', page, [
			'Your Flow',
			'As you access your report, the first section you will explore is your Flow. Your "Flow" refers to your top 5 traits, the "thinking and doing" that comes so naturally to you.',
			'Like traveling downstream or rolling downhill, these are the parts of you that come out effortlessly, that bring you energy when you use them, and can get you in that "flow state", whether you are busy at work or grooving at home.',
			'Your flow is made up of the traits that are the most dominant aspects of your personality, right now. These are your superpowers. And like every good superhero, you should focus on using them often. Within this section and others, you will be introduced to the trait score, which shows the relative strength of each trait in your profile.',
		]);
		page = await this.addTraitPages(this.flow, page);
		page = this.addSectionIntroPage('Friction', page, [
			'Your Friction',
			'The next part of the report is the opposite of your Flow. We call this your Friction.',
			'They are not weaknesses, but rather areas that feel a little less comfortable.',
			"These are the things that take more energy or effort for you to bring out, kinda like driving a car on square-shaped tires. It's important to know your friction, because spending too much of your time trying to embody these traits will leave you feeling exhausted.",
		]);
		page = await this.addTraitPages(this.friction, page);
		page = this.addSectionIntroPage('In-Between', page, [
			'Your In-Between',
			'In between your friction and your flow is what we aptly call your "In-Between". Depending on the situation, these traits could flex into your strengths or challenges.',
			'As you review your results, take note of the trait scores of the In-Between. This could give you an indication of how close they are to your Flow or Friction.',
		]);
		page = await this.addTraitPages(this.inBetween, page);
		page = this.addSectionIntroPage('Amoeaba', page, [
			'The Amoeba',
			'Time to bring it all together using a visual! Think back to Biology class. Remember the amoeba, the one-celled organism that is defined by its "unique movement patterns?" Here you can spot how your 25 traits influence your unique profile - based on their individual strength within you.',
			'Your flow will show up at the farther distance from the center of the amoeba, indicating stronger presence in your personality. Your friction will fall closer to the center of the amoeba.',
			'Your amoeba provides you one succinct view into the dynamic aspects of your personality. And just like how you are a unique person with unique perspective, experiences, and talents there are only a few people in the world who have an amoeba shaped like yours.',
			'So, with all this beautiful uniqueness in mind, now is your chance to share with others!',
		]);
		page = await this.addAmoeabaPage(page);
		this.addSectionIntroPage("What's next?", page, [
			"What's Next?",
			"Now you're ready to invite friends, colleagues and family to take the assessment! They will also discover what energizes them at work and home and gain insights for using their strengths with ease.",
			'Sharing allows you to now compare your results, recognizing what you have in common as well as how you are motivated and talented differently.',
			"We're thrilled you are a part of the community. Thanks for listening to this amazingly interesting demo… Now, dig in!",
		]);
	}

	private async addCoverPage(): Promise<void> {
		const background = await this.getImageData(
			`/assets/img/report/report-export-bg@2x.jpg`
		);
		this.doc.addImage(background, 'JPEG', 0, 0, pxToIn(816), pxToIn(1056));

		const logo = await this.getImageData(`/assets/img/logo-dark.png`);
		this.doc.addImage(
			logo,
			'JPEG',
			pxToIn(300),
			pxToIn(490.75),
			pxToIn(200),
			pxToIn(65)
		);

		this.setNormalFont().text(
			[
				this.user.profile.name,
				`ID: ${this.user.id}`,
				DateTime.now().toLocaleString(DateTime.DATE_MED),
			],
			4.25,
			6.5,
			{ align: 'center', lineHeightFactor }
		);

		this.doc.setFontSize(12).text('© 2023 ShareaStrength LLC.', 4.25, 10, {
			align: 'center',
		});
	}

	private async addIndexPage(): Promise<void> {
		this.doc.addPage();

		this.addPageHeader('Index');

		this.doc
			.setFont('helvetica', 'normal', 'bold')
			.setFontSize(40)
			.text('Index', 4.25, 3, { align: 'center' });

		this.doc
			.setFont('helvetica', 'normal', 'normal')
			.setFontSize(FONT_SIZE_NORMAL);

		this.indexItems.forEach(({ title, page }, index) => {
			--index;
			const top = 5.5 + (index ? index * 0.375 : 0);
			this.doc
				.setTextColor(PURPLE)
				.text(title, PADDING, top, { align: 'left' });
			this.doc
				.setTextColor(BLACK)
				.text(page, 8.5 - PADDING, top, { align: 'right' });
		});

		this.addPageFooter(2);
	}

	private addProfileIntroPage(): void {
		this.doc.addPage();

		this.addPageHeader('Profile');

		this.setHeaderFont().text('Profile', PADDING, 4, { align: 'left' });

		this.setNormalFont().text(
			`Welcome to your personal profile. This page includes a visual overview of the results of your assessment and what you can do with all the information.`,
			PADDING,
			4.5,
			{
				lineHeightFactor,
				maxWidth,
			}
		);

		this.setNormalFont('bold').text(
			'You can also review the critical components of each trait:',
			PADDING,
			5.125
		);

		const lines = [
			[
				'Your score:',
				'the strength of this trait in your assessment results (out of 100%)',
			],
			['At Your Best:', 'how this becomes your superpower'],
			[
				'In Overdrive:',
				'how you might overuse or overpower in your relationships',
			],
		];

		lines.reduce(
			(top, [bold, normal]) =>
				this.addSplitLine(bold, normal, PADDING, top),
			5.5
		);

		this.addPageFooter(3);
	}

	private addSectionIntroPage(
		title: string,
		pageNumber: number,
		content: string[]
	): number {
		this.doc.addPage();

		this.addPageHeader(title);

		const header = content.shift();
		this.setHeaderFont().text(header, PADDING, 4);

		const spacedContent = this.addLineBreaksToContent(content);
		this.setNormalFont().text(spacedContent, PADDING, 4.5, {
			lineHeightFactor,
			maxWidth,
		});

		this.addPageFooter(pageNumber);

		return pageNumber + 1;
	}

	private async addTraitPages(
		traits: TraitResultWithTrait[],
		startPage: number
	): Promise<number> {
		let nextPage = startPage;
		for (let i = 0; i < traits.length; i++) {
			nextPage = await this.addTraitPage(traits[i], nextPage);
		}
		return nextPage;
	}

	private async addTraitPage(
		trait: TraitResultWithTrait,
		startPage: number
	): Promise<number> {
		let nextPage = startPage;

		nextPage = await this.addTraitCoverPage(trait, nextPage);
		nextPage = this.addTraitDetailsPage(trait, nextPage);

		return nextPage;
	}

	private async addTraitCoverPage(
		trait: TraitResultWithTrait,
		pageNumber: number
	): Promise<number> {
		this.doc.addPage();

		this.addPageHeader(trait.trait.name_general);

		const imageRatio = 606 / 534; //w/h
		const traitImage = await this.getImageData(
			`/assets/img/traits/${getTraitSafeName(
				trait.trait.name_general
			)}.jpg`
		);
		const width = pxToIn(150),
			height = pxToIn(150 / imageRatio);

		const x = 4.25 - width / 2;
		this.doc.addImage(traitImage, 'JPEG', x, 2.25, width, height);

		let top = 2.25 + height + LINE_SPACING;
		this.setHeaderFont().text(trait.trait.name_general, 4.25, top, {
			align: 'center',
		});

		top += LINE_SPACING;

		this.setNormalFont('bold').text(`Rank #${trait.rank}`, 4.25, top, {
			align: 'center',
		});

		top += 1.5;

		this.setQuoteFont().text(trait.trait.quote.content, 4.25, top, {
			align: 'center',
			lineHeightFactor,
			maxWidth,
		});

		top += 2;
		this.setNormalFont().text('You scored', 4.25, top, { align: 'center' });

		top += 0.5;
		this.doc
			.setFontSize(36)
			.text(`${trait.dominance}%`, 4.25, top, { align: 'center' });

		this.addPageFooter(pageNumber);

		return pageNumber + 1;
	}

	private addTraitDetailsPage(
		trait: TraitResultWithTrait,
		pageNumber: number
	): number {
		this.doc.addPage();

		this.addPageHeader(trait.trait.name_general);

		let top = 1.5;
		this.traitMeta.forEach(({ key, title }, index) => {
			this.setNormalFont('bold').text(title, PADDING, top);
			top += LINE_SPACING;
			this.setNormalFont().text(trait.trait[key].content, PADDING, top, {
				lineHeightFactor,
				maxWidth,
			});
			top += 2.5;
		});

		this.addPageFooter(pageNumber);

		return pageNumber + 1;
	}

	private async addAmoeabaPage(pageNumber: number): Promise<number> {
		this.doc.addPage();

		this.addPageHeader('Amoeaba');

		await this.addAmoeabaImage();

		this.addPageFooter(pageNumber);

		return pageNumber + 1;
	}

	private async addAmoeabaImage(): Promise<void> {
		const sanitizedSvgCode = this.amoeabaSvgCode
			.replace(/var\(--primary\)/g, PURPLE)
			.replace(/var\(--navy\)/g, BLACK)
			.replace(/var\(--white\)/g, '#ffffff')
			.replace(/var\(--opacity, 0\)/g, '1')
			.replace(/style="stroke: transparent/gi, 'style="display: none')
			.replace(/var\(--font-family-sans-serif\)/g, 'helvetica')
			.replace('translate( 311, 311)', 'translate(311, 311) scale(.85)');

		console.log(sanitizedSvgCode);

		let v = null;
		const canvas = document.querySelector('canvas');
		const ctx = canvas.getContext('2d');

		v = Canvg.fromString(ctx, sanitizedSvgCode);
		v.start();

		const imageUrl = canvas.toDataURL('image/png', 1);
		const size = 7.5;
		this.doc.addImage(imageUrl, 'PNG', 0.5, 1.5, size, size);

		canvas.style.display = 'none';
	}

	private addPageHeader(title: string): void {
		this.doc
			.setFont('helvetica', 'normal', 'bold')
			.text(title, 4.25, 1, { align: 'center' });
	}

	private addPageFooter(pageNumber: number): void {
		this.doc
			.setFont('helvetica', 'normal', 'normal')
			.setFontSize(11)
			.text('© 2023 ShareaStrength LLC.', 4.25, 10, {
				align: 'center',
			})
			.text(`Page ${pageNumber}`, 4.25, 10.2, { align: 'center' });
	}

	private addSplitLine(
		boldText: string,
		normalText: string,
		x: number,
		y: number
	): number {
		this.setNormalFont('bold').text(boldText, x, y);
		this.setNormalFont().text(
			normalText,
			1 + this.getTextWidth(boldText),
			y
		);
		return y + LINE_SPACING;
	}

	private setFileName(user: User): void {
		this.fileName = `ShareaStrength_Report-${user.profile.name.replace(
			/\ /g,
			'_'
		)}-${DateTime.now().toISODate()}.pdf`;
	}

	private setHeaderFont(): jsPDF {
		return this.doc
			.setFont('helvetica', 'normal', 'bold')
			.setFontSize(FONT_SIZE_HEADER);
	}

	private setNormalFont(fontWeight = 'normal'): jsPDF {
		return this.doc
			.setFont('helvetica', 'normal', fontWeight)
			.setFontSize(FONT_SIZE_NORMAL);
	}

	private setQuoteFont(): jsPDF {
		return this.doc
			.setFont('helvetica', 'italic', 'normal')
			.setFontSize(FONT_SIZE_QUOTE);
	}

	private async getImageData(imageUrl: string): Promise<any> {
		const blob = await firstValueFrom(
			this.http.get(imageUrl, {
				responseType: 'blob',
			})
		);
		let resultBase64 = await new Promise((resolve) => {
			let fileReader = new FileReader();
			fileReader.onload = (e) => resolve(fileReader.result);
			fileReader.readAsDataURL(blob);
		});
		return resultBase64;
	}

	private addLineBreaksToContent(content: string[]): string[] {
		return content.reduce((lines, current) => {
			return [...lines, current, ''];
		}, []);
	}

	private getTextWidth(
		text: string,
		fontSize: number = FONT_SIZE_NORMAL,
		fontWeight: string = 'normal'
	): number {
		this.doc
			.setFontSize(fontSize)
			.setFont('helvetica', 'normal', fontWeight);

		const width =
			(this.doc.getStringUnitWidth(`${text}__`) * fontSize) / 72;

		this.setNormalFont();

		return width;
	}
}
