import { Component } from '@angular/core';
import { DemographicsService } from 'src/app/services/demographics.service';
import { Trait } from 'src/app/interfaces/trait';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, Observable, tap } from 'rxjs';
import { getTraitSafeName } from 'lib/trait/get-safe-name';
import { InsightsService } from 'src/app/services/insights.service';

@Component({
	selector: 'app-trait-detail',
	templateUrl: './trait-detail.component.html',
	styleUrls: ['./trait-detail.component.scss'],
})
export class TraitDetailComponent {
	content = {
		flow: 'sweetspot',
		friction: 'blindspot',
		strategy: 'strategy',
	};

	trait$: Observable<Trait> = this.route.data.pipe(
		map((d) => d?.trait),
		map((trait) => ({
			...trait,
			name_safe: getTraitSafeName(trait.name_general),
		}))
	);

	insights$: Observable<any> = this.trait$.pipe(
		switchMap((trait) =>
			this.insightsService.getInsightsForTrait(trait.id)
		),
		tap((insights) => console.log(insights))
	);

	demographics$: Observable<any> = this.trait$.pipe(
		switchMap((trait) => this.demographicsService.getDemographics(trait.id))
	);

	returnUrl$: Observable<{ title: string; routerLink: string }> =
		this.route.queryParams.pipe(
			map((params) => {
				return params?.returnUrl
					? {
							title: 'Back',
							routerLink: params.returnUrl,
					  }
					: {
							title: 'All Traits',
							routerLink: '/traits',
					  };
			})
		);

	constructor(
		private route: ActivatedRoute,
		private insightsService: InsightsService,
		private demographicsService: DemographicsService
	) {}
}
