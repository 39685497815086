export interface NavigationLink {
	text: string;
	routerLink: string | string[];
	exactMatch: boolean;
}

export const NAVIGATION_LINKS: NavigationLink[] = [
	{
		text: 'Home',
		routerLink: '/me',
		exactMatch: true,
	},
	{
		text: 'Following',
		routerLink: '/me/community',
		exactMatch: false,
	},
	{
		text: 'Report',
		routerLink: '/me/report',
		exactMatch: true,
	},
	// {
	// 	text: 'Notifications',
	// 	routerLink: '/me/account/notifications',
	// 	exactMatch: true,
	// },
	{
		text: 'Account',
		routerLink: 'me/account',
		exactMatch: false,
	},
	{
		text: 'Invite',
		routerLink: `/me/invite`,
		exactMatch: true,
	},
	/**
	 * @devnote Disabling insights for beta
	 */
	// {
	// 	text: 'Insights',
	// 	routerLink: '/insights',
	// 	exactMatch: false
	// }
];
