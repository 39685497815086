<div class="layout-grid container-fluid container-xl">
	<div class="navbar-start">
		<a routerLink="/" class="navbar-brand">
			<sas-logo [fill]="navbarColorLight ? '#000' : '#fff'"></sas-logo>
		</a>
	</div>
	<!--/.navbar-start-->

	<div
		class="navbar-center"
		*ngIf="deviceService.deviceSizeMin(breakPoint) | async"
	>
		<span class="current-page-title">{{ currentPageTitle }}</span>
	</div>
	<!--/.navbar-center-->

	<div class="navbar-end">
		<app-searchbar
			*ngIf="
				(showSearch$ | async) &&
				(deviceService.deviceSizeMin(breakPoint) | async)
			"
			[expanded]="true"
		></app-searchbar>
		<app-mobile-menu
			*ngIf="deviceService.deviceSizeMax(breakPoint) | async"
		></app-mobile-menu>
	</div>
	<!--/.navbar-end-->
</div>
