import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, map } from 'rxjs';
import { MeService } from '../../me/services/me.service';
import { USER_ROLE_ID_MAP } from 'src/app/interfaces/user-role';

@Injectable({ providedIn: 'root' })
export class AdminCanActivateGuard  {
	constructor(private meService: MeService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this.meService
			.getUserRoles()
			.pipe(
				map((roles) =>
					roles.some(
						(role) =>
							role.id === USER_ROLE_ID_MAP.SUPERADMIN ||
							role.id === USER_ROLE_ID_MAP.ADMIN
					)
				)
			);
	}
}
