import { createAction, props } from '@ngrx/store';
import { AssessmentContext } from './interfaces/assessment-context';
import { AssessmentQuestionResponse } from './interfaces/assessment-question-response';
import { AssessmentQuestion } from './interfaces/assessment-question';

export const loadAssessmentContext = createAction(
	'[Assessment] Load Context',
	props<AssessmentContext>()
);

// TODO: revisit...
export const assessmentResponseStored = createAction(
	'[Assessment] Response Stored',
	props<{ response: AssessmentQuestionResponse | AssessmentQuestion }>()
);
