import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	APP_VERSION,
	AngularFireAnalyticsModule,
	AngularFireAnalytics,
	ScreenTrackingService,
	UserTrackingService,
} from '@angular/fire/compat/analytics';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';

let analyticsImports = [],
	analyticsProviders = [];

if (environment.production || environment?.analytics_debug) {
	analyticsImports = [
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAnalyticsModule,
	];
	analyticsProviders = [
		AngularFireAnalytics,
		ScreenTrackingService,
		UserTrackingService,
		{
			provide: APP_VERSION,
			useValue: environment.version,
		},
	];
}

@NgModule({
	declarations: [],
	imports: [CommonModule, ...analyticsImports],
	providers: [...analyticsProviders],
})
export class AnalyticsModule {}
