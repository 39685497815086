import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {
	CardBodyComponent,
	CardBaseComponent,
	CardFooterComponent,
	CardHeaderComponent,
} from './card';
import { ActionUserImageComponent } from './action';
import { LikeComponent } from './action';
import { DirectivesModule } from '../directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SelectUserComponent } from './action/select-user/select-user.component';
import { SelectTraitComponent } from './action/select-trait/select-trait.component';
import { TraitPopoverComponent } from './action/trait-popover/trait-popover.component';
import { SelectTopFiveComponent } from './feedback/select-top-five/select-top-five.component';
import { OrderTopFiveComponent } from './feedback/order-top-five/order-top-five.component';
import { TraitIconBlockComponent } from './trait/trait-icon-block/trait-icon-block.component';
import { TraitIconCircleComponent } from './trait/trait-icon-circle/trait-icon-circle.component';
import { InfoPopoverComponent } from './action/info-popover/info-popover.component';
import { TraitInformationCardComponent } from './trait/trait-information-card/trait-information-card.component';
import { TraitTabsTopFiveComponent } from './trait/trait-tabs-top-five/trait-tabs-top-five.component';
import { ContentLockedComponent } from './alert/content-locked/content-locked.component';
import { UserTraitComparisonComponent } from './user/user-trait-comparison/user-trait-comparison.component';
import { UserTraitListComponent } from './user/user-trait-list/user-trait-list.component';
import { UserResultComparisonBarChartComponent } from './chart/user-result-comparison-bar-chart/user-result-comparison-bar-chart.component';
import { TraitAbstractCardComponent } from './trait/trait-abstract-card/trait-abstract-card.component';
import { PolaritiesComponent } from './trait/trait-polarites/polarities/polarities.component';
import { PolarityComponent } from './trait/trait-polarites/polarity/polarity.component';
import { TraitsModalComponent } from './trait/traits-modal/traits-modal.component';
import { TraitInformationSimpleComponent } from './trait/trait-information-simple/trait-information-simple.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgComponent } from './svg/svg.component';
import { UserImageComponent } from './user/user-image/user-image.component';
import { UserTraitListIconsComponent } from './user/user-trait-list-icons/user-trait-list-icons.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserListItemComponent } from './user/user-list-item/user-list-item.component';
import { IconHeadingComponent } from './text/icon-heading/icon-heading.component';
import { TraitInsightComponent } from './trait/trait-insight/trait-insight.component';
import { UserResultComparisonAmoebaChartComponent } from './chart/user-result-comparison-amoeba-chart/user-result-comparison-amoeba-chart.component';
import { UserGreetingComponent } from './user/user-greeting/user-greeting.component';
import { AmoebaComponent } from './chart/amoeba/amoeba.component';
import { SliderComponent } from './slider/slider/slider.component';
import { SlideComponent } from './slider/slide/slide.component';
import { UserComparisonTeaseComponent } from './user/user-comparison-tease/user-comparison-tease.component';
import { UserTeaseComponent } from './user/user-tease/user-tease.component';
import { UserCompareListComponent } from './user/user-compare-list/user-compare-list.component';
import { UserSuggestListComponent } from './user/user-suggest-list/user-suggest-list.component';
import { UserTopFiveComponent } from './user/user-top-five/user-top-five.component';
import { UserFollowButtonComponent } from './user/user-follow-button/user-follow-button.component';
import { JumboTextComponent } from './text/jumbo-text/jumbo-text.component';
import { CopyrightComponent } from './brand/copyright/copyright.component';
import { TraitIconComponent } from './trait/trait-icon/trait-icon.component';
import { UserTraitDetailsComponent } from './user/user-trait-details/user-trait-details.component';
import { LogoComponent } from './brand/logo/logo.component';
import { LoadingIndicatorComponent } from './loaders/loading-indicator/loading-indicator.component';
import { TraitTableComponent } from './trait/trait-table/trait-table.component';
import { SelectedTopFiveComponent } from './feedback/selected-top-five/selected-top-five.component';
import { OrderedTopFiveComponent } from './feedback/ordered-top-five/ordered-top-five.component';
import { UserTopTraitComparisonComponent } from './user/user-top-trait-comparison/user-top-trait-comparison.component';
import { UserFeedbackComparisonComponent } from './user/user-feedback-comparison/user-feedback-comparison.component';
import { ToggleComponent } from './display/toggle/toggle.component';
import { FlexBoxComponent } from './display/flex-box/flex-box.component';
import { UserCompareCardComponent } from './user/user-compare-card/user-compare-card.component';
import { TraitModalComponent } from './trait/trait-modal/trait-modal.component';
import { UserSidebarComponent } from './user/user-sidebar/user-sidebar.component';
import { InsightGenericComponent } from './insights/insight-generic/insight-generic.component';
import { SelectTopFiveComponent as SelectTopFiveComponentV3 } from './feedback/v3/select-top-five/select-top-five.component';
import { SelectOrderTopFiveReviewComponent } from './feedback/v3/select-order-top-five-review/select-order-top-five-review.component';
import { InsightGroupComponent } from './insights/v3/insight-group/insight-group.component';
import { InsightTraitComponent } from './insights/v3/insight-trait/insight-trait.component';
import { TestingModule } from '../modules/testing/testing.module';
import { InsightGenericComponent as InsightGenericComponentV3 } from './insights/v3/insight-generic/insight-generic.component';
import { UserShareResultsComponent } from './user/user-share-results/user-share-results.component';
import { TraitListComponent } from './trait/trait-list/trait-list.component';
import { UserTraitScoreWithIconComponent } from './user/user-trait-score-with-icon/user-trait-score-with-icon.component';
import { UserTraitPrimaryComponent } from './user/user-trait-primary/user-trait-primary.component';
import { UserTraitSecondaryComponent } from './user/user-trait-secondary/user-trait-secondary.component';
import { TraitsModalV2Component } from './trait/traits-modal-v2/traits-modal-v2.component';
import { InsightComponent } from './insights/v3/insight/insight.component';
import { DummyComponent } from './dummy/dummy.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		// ComponentsModule,
		NgbModule,
		TestingModule,
	],
	declarations: [
		CardBaseComponent,
		CardBodyComponent,
		CardFooterComponent,
		CardHeaderComponent,
		ActionUserImageComponent,
		LikeComponent,
		SelectUserComponent,
		SelectTraitComponent,
		TraitPopoverComponent,
		SelectTopFiveComponent,
		OrderTopFiveComponent,
		TraitIconBlockComponent,
		TraitIconCircleComponent,
		InfoPopoverComponent,
		TraitInformationCardComponent,
		TraitTabsTopFiveComponent,
		ContentLockedComponent,
		UserTraitComparisonComponent,
		UserTraitListComponent,
		UserResultComparisonBarChartComponent,
		TraitAbstractCardComponent,
		PolaritiesComponent,
		PolarityComponent,
		TraitsModalComponent,
		TraitInformationSimpleComponent,
		SvgComponent,
		UserImageComponent,
		UserTraitListIconsComponent,
		UserListComponent,
		UserListItemComponent,
		IconHeadingComponent,
		TraitInsightComponent,
		UserResultComparisonAmoebaChartComponent,
		UserGreetingComponent,
		AmoebaComponent,
		SliderComponent,
		SlideComponent,
		UserComparisonTeaseComponent,
		UserTeaseComponent,
		UserCompareListComponent,
		UserSuggestListComponent,
		UserTopFiveComponent,
		UserFollowButtonComponent,
		JumboTextComponent,
		CopyrightComponent,
		TraitIconComponent,
		UserTraitDetailsComponent,
		LogoComponent,
		LoadingIndicatorComponent,
		TraitTableComponent,
		SelectedTopFiveComponent,
		OrderedTopFiveComponent,
		UserTopTraitComparisonComponent,
		UserFeedbackComparisonComponent,
		ToggleComponent,
		FlexBoxComponent,
		UserCompareCardComponent,
		TraitModalComponent,
		UserSidebarComponent,
		InsightGenericComponent,
		SelectTopFiveComponentV3,
		SelectOrderTopFiveReviewComponent,
		InsightGroupComponent,
		InsightTraitComponent,
		InsightGenericComponentV3,
		UserShareResultsComponent,
		TraitListComponent,
		UserTraitScoreWithIconComponent,
		UserTraitPrimaryComponent,
		UserTraitSecondaryComponent,
		TraitsModalV2Component,
		InsightComponent,
		DummyComponent,
	],
	exports: [
		CardBaseComponent,
		CardBodyComponent,
		CardFooterComponent,
		CardHeaderComponent,
		ActionUserImageComponent,
		LikeComponent,
		SelectTraitComponent,
		SelectUserComponent,
		TraitPopoverComponent,
		SelectTopFiveComponent,
		OrderTopFiveComponent,
		TraitIconBlockComponent,
		TraitIconCircleComponent,
		InfoPopoverComponent,
		TraitInformationCardComponent,
		TraitTabsTopFiveComponent,
		ContentLockedComponent,
		UserTraitComparisonComponent,
		UserTraitListComponent,
		UserResultComparisonBarChartComponent,
		TraitAbstractCardComponent,
		PolaritiesComponent,
		PolarityComponent,
		TraitsModalComponent,
		SvgComponent,
		UserImageComponent,
		UserTraitListIconsComponent,
		UserListComponent,
		UserListItemComponent,
		IconHeadingComponent,
		TraitInsightComponent,
		UserResultComparisonAmoebaChartComponent,
		UserGreetingComponent,
		AmoebaComponent,
		SliderComponent,
		SlideComponent,
		UserComparisonTeaseComponent,
		UserTeaseComponent,
		UserCompareListComponent,
		UserSuggestListComponent,
		UserTopFiveComponent,
		UserFollowButtonComponent,
		JumboTextComponent,
		TraitIconComponent,
		UserTraitDetailsComponent,
		LogoComponent,
		LoadingIndicatorComponent,
		TraitTableComponent,
		SelectedTopFiveComponent,
		OrderedTopFiveComponent,
		UserTopTraitComparisonComponent,
		UserFeedbackComparisonComponent,
		ToggleComponent,
		FlexBoxComponent,
		UserCompareCardComponent,
		TraitModalComponent,
		UserSidebarComponent,
		InsightGenericComponent,
		SelectTopFiveComponentV3,
		SelectOrderTopFiveReviewComponent,
		InsightGroupComponent,
		InsightTraitComponent,
		InsightGenericComponentV3,
		UserShareResultsComponent,
		TraitListComponent,
		UserTraitScoreWithIconComponent,
		UserTraitPrimaryComponent,
		UserTraitSecondaryComponent,
		TraitsModalV2Component,
		DummyComponent,
		InsightComponent,
	],
})
export class SasUiModule {}
