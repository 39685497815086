import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TraitsRoutingModule } from './traits-routing.module';
import { TraitsComponent } from './containers/traits/traits.component';
import { TraitDetailComponent } from './containers/trait-detail/trait-detail.component';
import { SasUiModule } from 'src/app/ui/sas-ui.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TraitDetailHeaderComponent } from './components/trait-detail-header/trait-detail-header.component';
import { TraitFeedComponent } from './components/trait-feed/trait-feed.component';
import { TraitDetailsComponent } from './components/trait-details/trait-details.component';
import { TraitFriendsComponent } from './components/trait-friends/trait-friends.component';

@NgModule({
	imports: [
		CommonModule,
		TraitsRoutingModule,
		SasUiModule,
		DirectivesModule,
		NgbModule,
	],
	declarations: [
		TraitsComponent,
		TraitDetailComponent,
		TraitDetailHeaderComponent,
		TraitFeedComponent,
		TraitDetailsComponent,
		TraitFriendsComponent,
	],
})
export class TraitsModule {}
