import { Component, inject } from '@angular/core';
import { TraitService } from 'src/app/services/trait.service';
import { Trait } from 'src/app/interfaces/trait';
import { Observable, map } from 'rxjs';
import { getTraitSafeName } from 'lib/trait/get-safe-name';

@Component({
	selector: 'app-traits',
	templateUrl: './traits.component.html',
	styleUrls: ['./traits.component.scss'],
})
export class TraitsComponent {
	traitService = inject(TraitService);

	traits$: Observable<Trait[]> = this.traitService.traits.pipe(
		map((traits) =>
			traits
				.sort((a, b) => (a.name_general > b.name_general ? 1 : -1))
				.map((trait) => ({
					...trait,
					name_safe: getTraitSafeName(trait.name_general),
				}))
		)
	);
}
