<sas-card
	class="card--trait-secondary mb-3"
	*ngFor="let trait of traits$ | async"
>
	<div class="card-img">
		<svg [appSpriteIcon]="trait.name_safe"></svg>
	</div>
	<sas-card-body>
		<p class="trait-rank">
			<strong>{{ trait.name_general }}</strong>
		</p>
		<p class="trait-haiku">
			{{ trait.haiku.content }}
			<a class="text-primary" [routerLink]="['/traits', trait.name_safe]"
				>View trait details</a
			>
		</p>
	</sas-card-body>
</sas-card>
