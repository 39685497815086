import { AuthenticationV3Service } from './modules/authentication/services/authentication-v3.service';
import { environment } from '../environments/environment';
import { filter } from 'rxjs';
import { HelpFormService } from './modules/testing/components/help-form/help-form.service';
import { NavigationEnd, Router } from '@angular/router';
import { SurveyModalService } from './modules/testing/components/survey-modal/survey-modal.service';
import { UserOnboardingModalService } from './components/user-onboarding-modal/user-onboarding-modal.service';
import { UserService } from './services/user.service';
import {
	Component,
	ElementRef,
	HostBinding,
	OnInit,
	ViewChild,
} from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	@HostBinding('attr.app-version')
	appVersion: string = environment.version;

	@HostBinding('class') hostClass: string;

	@ViewChild('navigation') navigationEl: ElementRef;

	@ViewChild('cta') ctaEl: ElementRef;

	constructor(
		private router: Router,
		private authService: AuthenticationV3Service,
		public userService: UserService,
		public userOnboardingModalService: UserOnboardingModalService,
		public surveyModalService: SurveyModalService,
		public helpFormService: HelpFormService
	) {}

	async ngOnInit(): Promise<void> {
		// Get Sanctum XSRF Token
		// this.authService.getCsrfToken().subscribe();

		this.router.events
			.pipe(
				// tap((e) => console.log('Route', e)),
				filter((e) => e instanceof NavigationEnd)
			)
			.subscribe(
				(event: NavigationEnd) =>
					(this.hostClass = `route-${event.urlAfterRedirects
						.split('/')
						.join('-')}`)
			);
	}
}
