import {
	Component,
	HostBinding,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	debounceTime,
	distinctUntilChanged,
	switchMap,
	tap,
	catchError,
	map,
	Observable,
	of,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
	selector: 'app-searchbar',
	templateUrl: './searchbar.component.html',
	styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit {
	query: string;

	searching = false;

	searchFailed = false;

	searchFormOpen = false;

	@HostBinding('class.expanded')
	@Input()
	expanded = false;

	@ViewChild('searchInput', { static: true }) searchInput: any;

	constructor(private http: HttpClient, private router: Router) {}

	ngOnInit() {}

	focusInput() {
		if (this.searchFormOpen) {
			this.searchFormOpen = false;
			return;
		}
		this.searchFormOpen = true;
		this.searchInput.nativeElement.focus();
	}

	formatResult = (user: any): string => user.profile.name;

	handleSelection(event: { item: any; preventDefault: any }): void {
		this.router
			.navigate(['/user', event.item.id])
			.then(() => (this.query = null));
	}

	search = (term$: Observable<string>) =>
		term$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => (this.searching = true)),
			switchMap((term) =>
				this.queryUsers(term).pipe(
					tap(() => (this.searchFailed = false)),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					})
				)
			),
			tap(() => (this.searching = false))
		);

	queryUsers(term: string): Observable<any> {
		if (term === '') {
			return of([]);
		}
		return this.http
			.post<any>(`${environment.api_base_url}/users`, { q: term })
			.pipe(
				map((users) => users),
				catchError((error) => {
					console.error(error);
					return of(false);
				})
			);
	}
}
