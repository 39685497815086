import { AppState, selectAssessment } from 'src/app/store';
import { AssessmentContext } from '../interfaces/assessment-context';
import { AssessmentQuestionResponse } from '../interfaces/assessment-question-response';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadAssessment } from 'src/app/store/assessment/assessment.actions';
import { loadAssessmentContext } from '../assessment.actions';
import { Observable, of, switchMap, tap } from 'rxjs';
import { selectAssessmentContext } from '../assessment.reducer';
import { Store } from '@ngrx/store';
import { UserAssessment } from 'src/app/store/assessment/assessment.model';

@Injectable({
	providedIn: 'root',
})
export class AssessmentV3Service {
	constructor(private http: HttpClient, private store: Store<AppState>) {}

	getLatestAssessmentForAuthenticatedUser(): Observable<UserAssessment> {
		return this.http.get<UserAssessment>(
			`${environment.api_base_url_v3}/me/assessments/current`
		);
	}

	getAssessmentContext(): Observable<any> {
		return this.getAssessmentFromStore().pipe(
			switchMap((assessment) =>
				this.getQuestionsForAssessment(assessment.id)
			)
		);
	}

	getAssessmentFromStore(): Observable<UserAssessment> {
		return this.store
			.select(selectAssessment)
			.pipe(
				switchMap((assessment) =>
					assessment ? of(assessment) : this.loadAssessment()
				)
			);
	}

	getQuestionsForAssessment(
		assessmentId: number
	): Observable<AssessmentContext> {
		return this.store
			.select(selectAssessmentContext)
			.pipe(
				switchMap((state) =>
					state.questions.length
						? of(state)
						: this.loadAssessmentQuestions(assessmentId)
				)
			);
	}

	storeQuestionResponse(responseObject: any): Observable<any> {
		console.log(responseObject);
		return this.store.select(selectAssessment).pipe(
			switchMap((assessment) =>
				this.http
					.patch<AssessmentQuestionResponse>(
						this.determineResponseEndpoint(
							assessment.id,
							responseObject
						),
						responseObject
					)
					.pipe(
						// TODO: revisit overhead...
						switchMap(() =>
							this.loadAssessmentQuestions(assessment.id)
						)
					)
			)
		);
	}

	completeAssessment(): Observable<void> {
		return this.store
			.select(selectAssessment)
			.pipe(
				switchMap((assessment) =>
					this.http.post<any>(
						`${environment.api_base_url_v3}/me/assessments/${assessment.id}/complete`,
						{ assessment_id: assessment.id }
					)
				)
			);
	}

	private loadAssessment(): Observable<UserAssessment> {
		return this.http
			.get<UserAssessment>(
				environment.api_base_url_v3 + `/me/assessments/current`
			)
			.pipe(
				tap((assessment) =>
					this.store.dispatch(loadAssessment(assessment))
				)
			);
	}

	private loadAssessmentQuestions(
		assessmentId: number
	): Observable<AssessmentContext> {
		return this.http
			.get<any>(
				`${environment.api_base_url_v3}/me/assessments/${assessmentId}/questions`
			)
			.pipe(
				tap((questions) =>
					this.store.dispatch(
						loadAssessmentContext({
							questions: Object.values(questions),
						})
					)
				)
			);
	}

	private determineResponseEndpoint(
		assessmentId: number,
		responseObject: any
	): string {
		return `${environment.api_base_url}/me/assessments/${assessmentId}/${
			responseObject.measurement_type === 'c' ? 'control-' : ''
		}responses/${responseObject.question_id}`;
	}
}
