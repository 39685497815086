import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { switchMap, map, filter, take, of, Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import * as UserActions from '../store/user/user.actions';
import { selectUser } from '../store';

/**
 * Confirms we've loaded the authenticated user's ordered
 * trait results. Loads them if not and adds them to
 * the user model in the store.
 */
@Injectable({ providedIn: 'root' })
export class UserTraitsCheckService  {
	constructor(private store: Store, private userService: UserService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.store.select(selectUser).pipe(
			filter((user) => user !== null),
			take(1),
			switchMap((user) =>
				user.orderedTraitIds && user.orderedTraitIds.length
					? of(true)
					: this.loadTraitsForUser(user)
			)
		);
	}

	private loadTraitsForUser(user): Observable<boolean> {
		return this.userService.getTraitSegmentForUser(user.id, 'top', 25).pipe(
			map((traitIds) => {
				console.log('User traits loaded');
				this.store.dispatch(UserActions.loadTraits({ traitIds }));
				return true;
			})
		);
	}
}
