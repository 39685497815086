<ng-container *ngIf="user">
	<ng-container *ngIf="link; else display">
		<!-- Link -->
		<a
			[routerLink]="['/user', user.id]"
			class="user-tease-content d-flex justify-content-start"
			[class.align-items-center]="!stacked"
			[class.flex-column]="stacked"
		>
			<ng-container *ngTemplateOutlet="userDetails"></ng-container>
		</a>
	</ng-container>

	<!-- Display-->
	<ng-template #display>
		<article
			class="user-tease-content d-flex justify-content-start"
			[class.align-items-center]="!stacked"
			[class.flex-column]="stacked"
		>
			<ng-container *ngTemplateOutlet="userDetails"></ng-container>
		</article>
	</ng-template>

	<!-- content -->
	<ng-template #userDetails>
		<div class="user-image user-image--fill mr-3">
			<user-image [user]="user" [large]="false" [isAuthUser]="isAuthUser"></user-image>
		</div>
		<div class="user-details" [class.text-white]="theme === 'light'">
			<p class="mb-0 user-name">
				<strong class="text-capitalize text-dark" [innerText]="user.profile?.name"></strong>
			</p>
			<p class="user-meta mb-0" [class.text-white]="theme === 'light'">
				<ng-container *ngIf="!isAuthUser">
				<a [class.disabled]="!user.profile?.profession"
					[class.text-white]="theme === 'light'"
					[routerLink]="compareLink"
					[queryParams]="getCompareLinkParams('profession')"
					[innerText]="(user.profile?.profession | titlecase) || 'No career info'"
				></a>, <a [class.disabled]="!user.profile?.city"
					[class.text-white]="theme === 'light'"
					[routerLink]="compareLink"
					[queryParams]="getCompareLinkParams('city')"
					[innerText]="user.profile?.city || 'No city info'"
				></a><br /></ng-container>
				<span
					[class.text-teal]="theme === 'light'"
					>{{ generation }}</span
				>
			</p>
		</div>
	</ng-template>
</ng-container>
