import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NavigationComponent } from 'src/app/components/navigation/navigation.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { SearchbarComponent } from 'src/app/components/searchbar/searchbar.component';
import { DirectivesModule } from '../directives/directives.module';
import { InviteFriendModalComponent } from './invite-friend-modal/invite-friend-modal.component';
import { TraitsModalComponent } from './traits-modal/traits-modal.component';
import { SasUiModule } from '../ui/sas-ui.module';
import { NavigationStaticComponent } from './navigation/components/navigation-static/navigation-static.component';
import { NavigationUserComponent } from './navigation/components/navigation-user/navigation-user.component';
import { LayoutComponent } from './layout/layout.component';
import { UserOnboardingModalComponent } from './user-onboarding-modal/user-onboarding-modal.component';
import { InviteFormComponent } from './invite-form/invite-form.component';
import { InviteListComponent } from './invite-list/invite-list.component';
import { InviteListItemComponent } from './invite-list-item/invite-list-item.component';
import { NavigationNotificationsComponent } from './navigation/components/navigation-notifications/navigation-notifications.component';
import { ReportExportComponent } from './report-export/report-export.component';
import { ReportPageComponent } from './report-export/components/report-page/report-page.component';
import { MobileMenuComponent } from './navigation/components/mobile-menu/mobile-menu.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		RouterModule,
		DirectivesModule,
		SasUiModule,
	],
	declarations: [
		NavigationComponent,
		FooterComponent,
		SearchbarComponent,
		InviteFriendModalComponent,
		InviteFormComponent,
		InviteListComponent,
		InviteListItemComponent,
		TraitsModalComponent,
		NavigationStaticComponent,
		NavigationUserComponent,
		LayoutComponent,
		UserOnboardingModalComponent,
		NavigationNotificationsComponent,
		ReportExportComponent,
		ReportPageComponent,
  MobileMenuComponent,
	],
	exports: [
		NavigationComponent,
		FooterComponent,
		SearchbarComponent,
		InviteFriendModalComponent,
		InviteFormComponent,
		InviteListComponent,
		InviteListItemComponent,
		TraitsModalComponent,
		NavigationStaticComponent,
		NavigationUserComponent,
		LayoutComponent,
		UserOnboardingModalComponent,
		ReportExportComponent,
		ReportPageComponent,
	],
})
export class ComponentsModule {}
