import { createReducer, on } from '@ngrx/store';
import {
	loadAssessmentContext,
	assessmentResponseStored,
} from './assessment.actions';
import { AssessmentContext } from './interfaces/assessment-context';

export const storeKey = 'assessmentContext';

const initialState: any = { questions: [] };

const handleLoadAssessmentContext = (
	state: AssessmentContext,
	context: AssessmentContext
) => context;

const _assessmentReducer = createReducer(
	initialState,
	on(loadAssessmentContext, handleLoadAssessmentContext)
);

export function assessmentReducer(state, action) {
	return _assessmentReducer(state, action);
}

export const selectAssessmentContext = (state: any) => state[storeKey];
