import { createReducer, on } from '@ngrx/store';
import { TeamUser } from 'src/app/interfaces/team-user';
import * as UserActions from './user.actions';
import {
	login,
	storeToken,
	loadPreferences,
	loadTraits,
	loadTeams,
	refresh,
	refreshTeams,
	logout,
} from './user.actions';
import { User } from './user.model';
import { UserPreferences } from 'src/app/interfaces/user-preferences';

export type Action = UserActions.All;

const defaultState: User = null;

// Reducer
export function UserReducer(state: User = defaultState, action: Action) {
	switch (action.type) {
		case UserActions.LOGIN: {
			return action.payload;
		}

		case UserActions.LOAD_TRAITS: {
			return Object.assign(
				{},
				{
					...state,
					orderedTraitIds: action.payload,
				}
			);
		}

		case UserActions.REFRESH: {
			/**
			 * Since tokens are only issued at login,
			 * it makes sense that we might want to
			 * hold onto the authentication token.
			 * ONLY the login method returns a user
			 * profile with an auth token.
			 *
			 * <austin@farmer.codes>
			 *
			 * Note: we need to look into deep copy issues from object.assign.
			 * The has nested objects that might cause issues.
			 *
			 * <lew@dankestudios.com>
			 */
			return Object.assign(
				{},
				{
					...state,
				},
				{
					...action.payload,
				},
				{
					preferences: {
						...state.preferences,
						...(action.payload.preferences || {}),
					},
					profile: {
						...state.profile,
						...(action.payload.profile || {}),
					},
					orderedTraitIds: {
						...state.orderedTraitIds,
						...(action.payload.orderedTraitIds || {}),
					},
				},
				{ token: state.token, token_expiration: state.token_expiration } // Add token expiration
			);
		}

		case UserActions.LOGOUT: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}

/**
 * NgRx@12.4.0 Updates
 *
 */
const initialState: User = null;

const handleLogin = (state: User, user: User): User => {
	return {
		...state,
		...user,
	};
};

const handleStoreToken = (state: User, payload: { token: string }): User => {
	return {
		...state,
		token: payload.token,
	};
};

const handleLoadPreferences = (
	state: User,
	preferences: UserPreferences
): User => {
	return {
		...state,
		preferences,
	};
};

const handleLoadTraits = (
	state: User,
	payload: { traitIds: number[] }
): User => {
	return {
		...state,
		orderedTraitIds: payload.traitIds,
	};
};

const handleLoadTeams = (state: User, payload: { teams: TeamUser[] }): User => {
	return {
		...state,
		teams: payload.teams,
	};
};

const handleRefresh = (state: User, user: Partial<User>) => {
	return {
		...state,
		...user,
		preferences: {
			...state.preferences,
			...(user.preferences || {}),
		},
		profile: {
			...state.profile,
			...(user.profile || {}),
		},
		orderedTraitIds: {
			...state.orderedTraitIds,
			...(user.orderedTraitIds || {}),
		},
		/**
		 * Token is obly returned by the login method and
		 * needs to be persisted throughout state updates.
		 */
		token: state.token,
		token_expiration: state.token_expiration,
	};
};

const handleRefreshTeams = (state: User, data: { teams: TeamUser[] }) => {
	return {
		...state,
		teams: [...data.teams],
	};
};

const handleLogout = (state: User) => {
	return null;
};

// tslint:disable-next-line:variable-name
const _userReducer = createReducer(
	initialState,
	on(login, handleLogin),
	on(storeToken, handleStoreToken),
	on(loadPreferences, handleLoadPreferences),
	on(loadTraits, handleLoadTraits),
	on(loadTeams, handleLoadTeams),
	on(refresh, handleRefresh),
	on(refreshTeams, handleRefreshTeams),
	on(logout, handleLogout)
);

export function userReducer(state, action) {
	return _userReducer(state, action);
}
