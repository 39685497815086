import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
} from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ReportService } from '../services/report.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { selectUser } from '../store';
import { UserAssessment } from '../store/assessment/assessment.model';
import { loadAssessment } from '../store/assessment/assessment.actions';
import { AssessmentV3Service } from '../modules/assessment/services/assessment-v3.service';

/**
 * Check if the user has completed their assessment.
 * If not, send to the assessment route.
 *
 * @author LWK
 */
@Injectable({
	providedIn: 'root',
})
export class UserAssessmentCheckService {
	constructor(
		private router: Router,
		private store: Store,
		private assessmentService: AssessmentV3Service,
		private reportService: ReportService
	) {}

	/**
	 * @Devnote if the user has not completed their assessment
	 * we'll return them to the assessment process, otherwise
	 * we'll proceed with routing.
	 */
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkAssessment().pipe(
			switchMap((assessment) => {
				if (!assessment || !assessment.complete) {
					// Don't return false because it's in the same route tree
					console.warn('ReRoute', '/me/assessment');
					this.router.navigate(['/', 'me', 'assessment']);
				}
				console.log(
					'Assessment check',
					'User has completed assessment'
				);
				return of(true);
			})
		);
	}

	canActivateChild = (
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) => this.canActivate(route, state);

	canDeactivate(
		component: any,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkAssessment('deactivate').pipe(
			tap((assessment) => {
				console.log(
					'Deactivate',
					currentRoute,
					currentState,
					assessment
				);
			}),
			switchMap((assessment) =>
				of(!!(assessment && assessment.complete))
			),
			tap((t) => console.log('Can Deactivate?', t))
		);
	}

	private checkAssessment(type: string = null): Observable<UserAssessment> {
		return this.store.select(selectUser).pipe(
			switchMap((user) =>
				this.assessmentService.getLatestAssessmentForAuthenticatedUser()
			),
			tap((assessment) => {
				console.log('Do store update', assessment);
				this.store.dispatch(loadAssessment(assessment));
			})
		);
	}
}
