import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	Observable,
	catchError,
	combineLatest,
	map,
	of,
	switchMap,
	tap,
} from 'rxjs';
import { ApiErrorResponse } from 'src/app/interfaces/responses/api-error';
import { AppState } from 'src/app/store';
import { User } from 'src/app/store/user/user.model';
import { environment } from 'src/environments/environment';
import {
	loadTeams,
	loadPreferences,
	login,
	logout,
	storeToken,
} from '../../../store/user/user.actions';
import { UserPreferences } from 'src/app/interfaces/user-preferences';
import { TeamUser } from 'src/app/interfaces/team-user';
import { ApiGenericeResponse } from 'src/app/interfaces/responses/api-generic-response';
import { UserAssessment } from 'src/app/store/assessment/assessment.model';
import { loadAssessment } from 'src/app/store/assessment/assessment.actions';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationV3Service {
	private auth_api_base_url = `${environment.api_base_url_v3}/auth`;
	constructor(private http: HttpClient, private store: Store<AppState>) {}

	/**
	 * Retrieves the Sanctum XSRF-TOKEN
	 *
	 * Response header will contain a SET-XSRF-TOKEN header
	 * which angular will use to automatically set the
	 * appropriate cookie and send with subsequent requests.
	 */
	getCsrfToken(): Observable<void> {
		return this.http.get<void>(`${this.auth_api_base_url}/csrf-cookie`);
	}

	/**
	 * Try to login user
	 */
	doLogin(
		email: string,
		password: string
	): Observable<boolean | ApiErrorResponse> {
		return this.http
			.post<{ two_factor: boolean; token: string }>(
				`${this.auth_api_base_url}/login`,
				{
					email,
					password,
				}
			)
			.pipe(
				// TODO: move this to an effects layer
				tap(({ token }) => this.store.dispatch(storeToken({ token }))),
				switchMap(() => this.loadRequiredUserData()),
				map(() => true),
				catchError((err: HttpErrorResponse) =>
					of((err?.error as ApiErrorResponse) ?? err)
				)
			);
	}

	/**
	 * Logout user
	 */
	doLogout(): Observable<ApiGenericeResponse> {
		return this.http
			.post<ApiGenericeResponse>(`${this.auth_api_base_url}/logout`, {})
			.pipe(
				catchError(() => of(null)),
				tap(() => this.store.dispatch(logout()))
			);
	}

	/**
	 * Register new user
	 */
	register(
		firstName: string,
		lastName: string,
		email: string,
		password: string,
		passwordConfirmation,
		referrerId?: string,
		ambassadorId?: string
	): Observable<ApiGenericeResponse> {
		return this.http.post<ApiGenericeResponse>(
			`${this.auth_api_base_url}/register`,
			{
				first_name: firstName,
				last_name: lastName,
				email,
				password,
				password_confirmation: passwordConfirmation,
				referrer_id: referrerId,
				ambassador_id: ambassadorId,
			}
		);
	}

	/**
	 * Requests password reset linl
	 */
	requestPasswordReset(email: string): Observable<ApiGenericeResponse> {
		return this.http.post<ApiGenericeResponse>(
			`${this.auth_api_base_url}/forgot-password`,
			{
				email,
			},
			{
				headers: new HttpHeaders({ Accept: 'application/json' }),
			}
		);
	}

	/**
	 * Perform password reset
	 */
	resetPassword(
		email: string,
		token: string,
		password: string,
		passwordConfirmation: string
	): Observable<ApiGenericeResponse> {
		return this.http.post<ApiGenericeResponse>(
			`${this.auth_api_base_url}/reset-password`,
			{
				email,
				token,
				password,
				password_confirmation: passwordConfirmation,
			}
		);
	}

	loadRequiredUserData(): Observable<any> {
		return combineLatest([
			this.getAuthenticatedUser(),
			this.getAuthenticatedUserPreferences(),
			this.getAuthenticatedUserAssessment(),
			this.getAuthenticatedUserTeams(),
		]);
	}

	/**
	 * Get user and persist to store
	 */
	getAuthenticatedUser(): Observable<User> {
		return this.http
			.get<User>(`${environment.api_base_url_v3}/me`)
			.pipe(tap((user) => this.store.dispatch(login(user))));
	}

	/**
	 * Get Preferences and persist to store
	 */
	getAuthenticatedUserPreferences(): Observable<UserPreferences> {
		return this.http
			.get<UserPreferences>(
				`${environment.api_base_url_v3}/me/preferences`
			)
			.pipe(
				tap((preferences) =>
					this.store.dispatch(loadPreferences(preferences))
				)
			);
	}

	/**
	 * Get authenticated user teams and persists
	 */
	getAuthenticatedUserTeams(): Observable<TeamUser[]> {
		return this.http
			.get<TeamUser[]>(`${environment.api_base_url_v3}/me/teams`)
			.pipe(tap((teams) => this.store.dispatch(loadTeams({ teams }))));
	}

	/**
	 * Get authenticated user assessment
	 */
	getAuthenticatedUserAssessment(): Observable<UserAssessment> {
		return this.http
			.get<UserAssessment>(
				`${environment.api_base_url_v3}/me/assessments/current`
			)
			.pipe(
				tap((assessment) =>
					this.store.dispatch(loadAssessment(assessment))
				)
			);
	}

	/**
	 * Check if Store User is Logged In
	 */
	validateIsActivelyAuthenticated(): Observable<boolean> {
		return this.http
			.get<boolean>(`${this.auth_api_base_url}/is-authenticated`)
			.pipe(
				map(() => true),
				catchError((err: ApiErrorResponse) => of(false))
			);
	}
}
