import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Observable, of, map, switchMap } from 'rxjs';
import { TraitsModalService } from 'src/app/components/traits-modal/traits-modal.service';
import { Trait } from 'src/app/interfaces/trait';
import { TraitInsight } from 'src/app/interfaces/trait-insight';
import { InsightsService } from 'src/app/services/insights.service';

@Component({
	selector: 'sas-traits-modal-v2',
	templateUrl: './traits-modal-v2.component.html',
	styleUrls: ['./traits-modal-v2.component.scss'],
})
export class TraitsModalV2Component implements OnInit {
	@Input('traits') traits: Trait[];

	@Input('traitIds') traitIds: number[];

	@Input('showSearch') showSearch = true;

	@Input('search') search = '';

	@Output() dismiss: EventEmitter<void> = new EventEmitter();

	get filteredTraits() {
		if (this.search) {
			return this.traits.filter(
				(item) =>
					item.name_general
						.toLowerCase()
						.indexOf(this.search.toLowerCase()) > -1
			);
		}
		return this.traits;
	}

	currentTrait$ = this.traitsModalService.currentTraitId$.pipe(
		map((id) =>
			this.traits && id ? this.traits.find((t) => t.id === id) : null
		)
	);

	insights$: Observable<{ trait: Trait; insight: TraitInsight }[]> =
		this.traitsModalService.currentTraitId$.pipe(
			switchMap((id) =>
				id
					? this.insightsService.getInsightsForTrait(id).pipe(
							map((insights) => {
								return insights.map((insight) => ({
									trait: this.traits.find((t) => t.id === id),
									insight,
								}));
							})
					  )
					: of([])
			)
		);

	constructor(
		public traitsModalService: TraitsModalService,
		private insightsService: InsightsService
	) {}

	ngOnInit() {
		console.log(this.traits);
	}

	close() {
		this.traitsModalService.hideTraitDetailModal();
		this.dismiss.emit();
	}

	showTrait(event, traitId) {
		event.preventDefault();
		this.traitsModalService.showTraitDetailModalForTrait(traitId);
	}

	showTraitModal(id: number) {
		this.traitsModalService.showTraitDetailModalForTrait(id);
	}

	handleDialogClick(event, force = false) {
		if (force || event.target.getAttribute('role') === 'dialog') {
			this.close();
		}
	}
}
