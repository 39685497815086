import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TraitService } from 'src/app/services/trait.service';
import { Trait } from 'src/app/interfaces/trait';
import { Observable, tap } from 'rxjs';
import { SeoGuardService } from 'src/app/middleware/seo-guard.service';

@Injectable({
	providedIn: 'root',
})
export class TraitResolverService {
	traitService = inject(TraitService);

	seoService = inject(SeoGuardService);

	resolve(route: ActivatedRouteSnapshot): Observable<Trait> {
		return this.traitService
			.getTraitBySafeName(route.paramMap.get('traitSafeName'))
			.pipe(
				tap((trait) => this.seoService.setPageTitle(trait.name_general))
			);
	}
}
