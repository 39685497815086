import { Action, createAction, props } from '@ngrx/store';
import { TeamUser } from 'src/app/interfaces/team-user';
import { Trait } from 'src/app/interfaces/trait';
import { User } from './user.model';
import { UserPreferences } from 'src/app/interfaces/user-preferences';
import { Team } from 'src/app/interfaces/team';

/**
 * LOGIN User
 * @param Object User
 */
export const LOGIN = '[User] LOGIN';
export class Login implements Action {
	readonly type = LOGIN;
	constructor(public payload: User) {}
}

/**
 * LOAD_TRAITS Trait[]
 * @param Array Trait
 */
export const LOAD_TRAITS = '[User] LOAD_TRAITS';
export class LoadTraits implements Action {
	readonly type = LOAD_TRAITS;
	constructor(public payload: number[]) {}
}

/**
 * REFRESH User
 * @param Object User
 */
export const REFRESH = 'User REFRESH';
export class Refresh implements Action {
	readonly type = REFRESH;
	constructor(public payload: Partial<User>) {}
}

/**
 * LOGOUT User
 */
export const LOGOUT = '[User] LOGOUT';
export class Logout implements Action {
	readonly type = LOGOUT;
	constructor() {}
}

export type All = Login | LoadTraits | Refresh | Logout;

/**
 * NgRx@12.4.0 Updates
 *
 */
export const login = createAction('[User] Login', props<User>());
export const storeToken = createAction(
	'[User] Bearer Token',
	props<{ token: string }>()
);
export const loadPreferences = createAction(
	'[User] Load Preferences',
	props<UserPreferences>()
);
export const loadTraits = createAction(
	'[User] Load Traits',
	props<{ traitIds: number[] }>()
);
export const loadTeams = createAction(
	'[User] Load Teams',
	props<{ teams: TeamUser[] }>()
);
export const refresh = createAction('[User] Refresh', props<Partial<User>>());
export const refreshTeams = createAction(
	'[User] Refresh Teams',
	props<{ teams: TeamUser[] }>()
);
export const logout = createAction('[User] Logout');
