<button class="btn btn-secondary" type="button" (click)="showMenu = true">
	<i class="fas fa-ellipsis-v"></i>
</button>

<nav *ngIf="showMenu">
	<div class="d-flex justify-content-between align-items-center">
		<sas-logo fill="#000"></sas-logo>
		<button
			class="btn btn-primary align-self-end"
			type="button"
			(click)="showMenu = false"
		>
			<i class="fas fa-times"></i>
		</button>
	</div>
	<ul class="menu">
		<li class="menu-item" *ngFor="let item of navItems$ | async">
			<a
				class="menu-item-link"
				routerLinkActive="current"
				[routerLink]="item.routerLink"
				[innerText]="item.text"
				(click)="showMenu = false"
			></a>
		</li>
		<li
			class="menu-item menu-item--secondary"
			*ngFor="let item of secondaryNavItems"
		>
			<a
				*ngIf="item.routerLink"
				class="menu-item-link"
				[routerLink]="item.routerLink"
				[innerText]="item.text"
				(click)="showMenu = false"
			></a>
			<a
				*ngIf="!item.routerLink"
				class="menu-item-link"
				[href]="item.url"
				[target]="item?.target"
				[innerText]="item.text"
				(click)="open($event, item)"
			></a>
		</li>
	</ul>
</nav>
