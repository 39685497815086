import { Component, HostBinding, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { selectAssessment } from '../../store';

import { SeoGuardService } from 'src/app/middleware/seo-guard.service';
import {
	DEFAULT_MOBILE_DEVICE_SIZE,
	DeviceService,
} from 'src/app/services/device.service';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
	@HostBinding('class') navbarClass =
		'navbar navbar-default navbar-expand sticky-top navbar-three-columns';

	@HostBinding('class.navbar-light')
	@Input()
	navbarColorLight = false;

	@HostBinding('id') id = 'app-navigation';

	@HostBinding('attr.aria-label') ariaLabel = 'Main Navigation';
	@HostBinding('attr.role') navRole = 'navigation';

	breakPoint = DEFAULT_MOBILE_DEVICE_SIZE;

	showSearch$: Observable<boolean> = this.store
		.select(selectAssessment)
		.pipe(map((assessment) => assessment?.complete));

	get currentPageTitle() {
		return this.seoGuardService.pageTitle;
	}

	@HostBinding('class.navbar-dark')
	get navbarColorDark() {
		return !this.navbarColorLight;
	}

	constructor(
		private store: Store,
		private seoGuardService: SeoGuardService,
		public deviceService: DeviceService
	) {}
}
