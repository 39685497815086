import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Trait } from '../interfaces/trait';
import { Observable, of, map, share, switchMap, filter } from 'rxjs';
import { getTraitSafeName } from 'lib/trait/get-safe-name';

/**
 * Trait Information Service
 *
 * @author LWK <lew@dankestudios.com>
 */
@Injectable({
	providedIn: 'root',
})
export class TraitService {
	/**
	 * Let's cache the traits locally on the first request
	 *
	 * Note: it'd be nice to move this into the store
	 *
	 * @var Trait[]
	 */
	private _traits: Trait[];
	public get traits(): Observable<Trait[]> {
		if (this._traits) {
			return of(this._traits).pipe(share());
		} else {
			return this.getTraits().pipe(
				switchMap((traits) => {
					this._traits = traits;
					return of(this._traits);
				})
			);
		}
	}

	constructor(private http: HttpClient) {}

	/**
	 * Get all traits
	 *
	 * Note: This method should no be called directly. Use the getter `traits`
	 * to properly retrieve the data set. We may want to add the ability to
	 * request only a part of the standard trait object.
	 *
	 * @author LWK <lew@dankestudios.com>
	 *
	 * @see TraitService.traits
	 */
	private getTraits(): Observable<Trait[]> {
		return this.http
			.get<Trait[]>(`${environment.api_base_url}/traits`)
			.pipe(
				filter((traits) => traits !== null),
				map((traits) => this._cleanupTraitContent(traits))
			);
	}

	/**
	 * Get a single trait
	 *
	 * Note: this will automatically retrieve all traits if
	 * they have not yet been loaded.
	 *
	 * @author LWK <lew@dankestudios.com>
	 *
	 * @param id traitId
	 */
	getTrait(id: number) {
		return this.traits.pipe(
			map((traits) => traits.find((trait) => trait.id === +id))
		);
	}

	getTraitBySafeName(safeName: string): Observable<Trait> {
		return this.traits.pipe(
			map((traits) =>
				traits.find(
					(trait) => getTraitSafeName(trait.name_general) === safeName
				)
			)
		);
	}

	/**
	 * Get Trait to trait Comparison
	 *
	 * @stubbed
	 * Get the strategy content for `fromTraitId`
	 * to `toTraitId`.
	 *
	 * @author LWK<lew@dankestudios.com>
	 */
	getTraitToTraitStrategy(
		fromTraitId: number,
		toTraitId: number
	): Observable<any> {
		return this.http.get<{
			from_trait: number;
			to_trait: number;
			content: string;
		}>(`${environment.api_base_url_v3}/traits/${fromTraitId}/${toTraitId}`);
	}

	/**
	 * Cleanup unescaped trait content
	 *
	 * @author LWK
	 */
	_cleanupTraitContent(traits: Trait[]): Trait[] {
		if (traits === null || traits === undefined) {
			return [];
		}
		return traits.map((trait) => {
			['blindspot', 'strategy', 'sweetspot', 'quote', 'haiku'].forEach(
				(key) => {
					trait[key].content = trait[key].content.replace(
						/\\"/g,
						'"'
					);
				}
			);
			return trait;
		});
	}
}
