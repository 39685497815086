export const firebase = {
	projectId: 'shareastrength',
	appId: '1:839227929035:web:6dfd2c93b0a069b8a7464e',
	databaseURL: 'https://shareastrength.firebaseio.com',
	storageBucket: 'shareastrength.appspot.com',
	apiKey: 'AIzaSyD_A42AR7W6LibHsRoKqWMqwmMYabv6wlY',
	authDomain: 'shareastrength.firebaseapp.com',
	messagingSenderId: '839227929035',
	measurementId: 'G-5VB8T0DCNR',
};
